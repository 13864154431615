import { Navigate, useLocation, useRoutes } from "react-router-dom";
import cookie from "utils/cookie";

// -----pages-------------------------------------------------------
import HomePage from "views/HomePage";
import Login from "views/Login";

// -----custom tools-------------------------------------------------------
import {
  LayoutWithTopBar,
  LayoutWithTopBarCRUD,
} from "layout/LayoutWithTopBar";
import { ROLE } from "utils/role";
import paths from "utils/webPath";
import AccountManagement from "views/AccountManagement";
import PeptideAdd from "views/Peptide/PeptideAdd";
import PeptideEdit from "views/Peptide/PeptideEdit";
import PeptideIntroEdit from "views/Peptide/PeptideIntroEdit";
import PeptideIntroView from "views/Peptide/PeptideIntroView";
import ProteinAdd from "views/Protein/ProteinAdd";
import ProteinEdit from "views/Protein/ProteinEdit";
import ProteinPeptideCls from "views/ProteinPeptideCls";
import ProteinPeptideSettings from "views/ProteinPeptideSettings";
import PeptideClassAdd from "views/Peptide/PeptideClassAdd";
// -----Router Guard-------------------------------------------------------
function ProtectedRoute({ children, redirectPath }) {
  const token = cookie.getCookie(cookie.keys.token);

  let location = useLocation();
  let fullPath = location.pathname + location.search;

  let isPermitted = true;
  const role_id = Number(cookie.getCookie(cookie.keys.roleId));

  if (fullPath === paths.site.accountManagement && role_id !== ROLE.ROOT) {
    isPermitted = false;
  }

  return token !== "" && token !== undefined && isPermitted ? (
    <LayoutWithTopBar>{children}</LayoutWithTopBar>
  ) : (
    <Navigate
      to={`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`}
    />
  );
}
function ProtectedRouteCRUD({ children, redirectPath }) {
  const token = cookie.getCookie(cookie.keys.token);

  let location = useLocation();
  let fullPath = location.pathname + location.search;

  let isPermitted = true;
  const role_id = Number(cookie.getCookie(cookie.keys.roleId));

  const permissionPaths = [
    paths.site.proteinEdit,
    paths.site.proteinAdd,
    paths.site.peptideEdit,
    paths.site.peptideAdd,
    paths.site.peptideIntroEdit,
  ];

  if (
    permissionPaths.includes(fullPath) &&
    role_id !== ROLE.ROOT &&
    role_id !== ROLE.MANAGER
  ) {
    isPermitted = false;
  }

  return token !== "" && token !== undefined && isPermitted ? (
    <LayoutWithTopBarCRUD>{children}</LayoutWithTopBarCRUD>
  ) : (
    <Navigate
      to={`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`}
    />
  );
}
function LoggedInRoute({ children }) {
  const token = cookie.getCookie(cookie.keys.token);
  return token !== "" && token !== undefined ? (
    <Navigate to={paths.site.home} />
  ) : (
    children
  );
}

// -----paths register-------------------------------------------------------
function Router() {
  let element = useRoutes([
    {
      path: "*",
      element: (
        <ProtectedRoute redirectPath={paths.site.home}>
          {/* <PageNotFound /> */}
          <HomePage />
        </ProtectedRoute>
      ),
    },
    {
      path: paths.auth.login,
      element: (
        <LoggedInRoute>
          <Login />
        </LoggedInRoute>
      ),
    },
    {
      path: paths.site.home,
      element: (
        <ProtectedRoute redirectPath={paths.site.home}>
          <HomePage />
        </ProtectedRoute>
      ),
    },
    {
      path: paths.site.proteinPeptideCls,
      element: (
        <ProtectedRoute redirectPath={paths.site.proteinPeptideCls}>
          <ProteinPeptideCls />
        </ProtectedRoute>
      ),
    },
    {
      path: paths.site.proteinPeptideSettings,
      element: (
        <ProtectedRoute redirectPath={paths.site.proteinPeptideSettings}>
          <ProteinPeptideSettings />
        </ProtectedRoute>
      ),
    },
    {
      path: paths.site.accountManagement,
      element: (
        <ProtectedRoute redirectPath={paths.site.accountManagement}>
          <AccountManagement />
        </ProtectedRoute>
      ),
    },

    //-----Protein-----
    {
      path: paths.site.proteinAdd,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.proteinAdd}>
          <ProteinAdd />
        </ProtectedRouteCRUD>
      ),
    },
    {
      path: paths.site.proteinEdit,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.proteinEdit}>
          <ProteinEdit />
        </ProtectedRouteCRUD>
        //<ProteinEdit />
      ),
    },
    //-----Peptide-----
    {
      path: paths.site.peptideAdd,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.peptideAdd}>
          <PeptideAdd />
        </ProtectedRouteCRUD>
      ),
    },
    {
      path: paths.site.peptideEdit,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.peptideEdit}>
          <PeptideEdit />
        </ProtectedRouteCRUD>
      ),
    },
    {
      path: paths.site.peptideIntro,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.peptideIntro}>
          <PeptideIntroView />
        </ProtectedRouteCRUD>
      ),
    },
    {
      path: paths.site.peptideIntroEdit,
      element: (
        <ProtectedRouteCRUD redirectPath={paths.site.peptideIntroEdit}>
          <PeptideIntroEdit />
        </ProtectedRouteCRUD>
      ),
    },
    {
      path: paths.site.peptideClsAdd,
      element: (
        <ProtectedRoute redirectPath={paths.site.peptideClsAdd}>
          <PeptideClassAdd />
        </ProtectedRoute>
      ),
    },
  ]);

  return element;
}

export default Router;
