import _axios from "axios";
import { snackBarObj, snackType } from "components/SnackBar";
import { setSnackbarInfo } from "store/mainSlice";
import cookie from "utils/cookie";
import paths from "utils/webPath";
import { apiDevDomain, apiProductDomain } from "./config";
import { gitInfo } from 'utils/version'

const restApiUrl = (gitInfo.branch === 'master' ? apiProductDomain : apiDevDomain)
// const isDev = true;
// const restApiUrl = isDev ? apiDevDomain : apiProductDomain;
const axios = (config) => {
  const instance = _axios.create({
    baseURL: config || restApiUrl,
    timeout: 10000,
  });

  instance.interceptors.request.use((request) => {
    // console.log("request:", request);
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      // console.log("response:", response);
      return response;
    }
    // (error) => {
    //   console.log("Error occurred", error);
    //   return Promise.reject(error);
    // }
  );

  return instance;
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const handleErrMsg = (err, dispatch, snackObj) => {
  try {
    if (err.response.status !== 200) {
      const errMsg = err.response.data.reason ?? err.response.data.message;
      snackObj.msg = errMsg;

      if (
        /* err.response.data.result_code === 910 ||*/
        err.response.data.result_code === 911
      ) {
        // Unauthorized Authentication(910) or Token Expired(911) → Logout
        dispatch(
          setSnackbarInfo(
            snackBarObj({
              isOpen: true,
              type: snackType.error,
              msg: err.response.data.message,
            })
          )
        );

        setTimeout(() => {
          cookie.removeAllCookie();
          window.location.replace(paths.site.home);
        }, 1000);
      } else {
        dispatch(setSnackbarInfo(snackObj));
      }

      return errMsg;
    }
    return "Unknown Error";
  } catch (error) {
    snackObj.msg = err.message;

    dispatch(setSnackbarInfo(snackObj));
    return err.message;
  }
};

// -----------------------------------------------------------------------------
const defaultToken = "c859ddf0-1308-11ed-99fb-2035a8aa5bcb";

const customHeaders = (headers, hasSpecificToken, token, isDefaultToken) => {
  headers["X-Auth-Token"] = cookie.getCookie(cookie.keys.token);

  if (hasSpecificToken) {
    headers["X-Auth-Token"] = token;
  }

  if (isDefaultToken) {
    headers["X-Auth-Token"] = defaultToken;
  }

  return headers;
};

export const apiPostConfig = ({
  endpoint,
  data,
  hasSpecificToken = false,
  token,
  isDefaultToken = false,
}) => {
  return {
    method: "post",
    url: endpoint,
    headers: customHeaders(
      { ...headers },
      hasSpecificToken,
      token,
      isDefaultToken
    ),
    data: JSON.stringify(data),
  };
};

export const apiGetConfig = ({
  endpoint,
  hasSpecificToken = false,
  token,
  isDefaultToken = false,
}) => {
  return {
    method: "get",
    url: endpoint,
    headers: customHeaders(
      { ...headers },
      hasSpecificToken,
      token,
      isDefaultToken
    ),
  };
};

export default axios();
