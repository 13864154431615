// -----official tools & third tools-------------------------------------------------------
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TableRow, TextField, Button, Box, Paper, Table, TableBody, TableContainer, IconButton, TableCell, InputAdornment } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import ppsScss from "assets/scss/views/ProteinPeptideSettings.module.scss";
import listLayoutScss from "assets/scss/layout/ListLayout.module.scss";
import _scss from 'layout/Crud.module.scss';

import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { snackInfo, snackType, snackBarObj } from 'components/SnackBar';
import StyledTableCell from 'styledComponents/StyledTableCell';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import cookie from "utils/cookie";
import paths from 'utils/webPath';
import { useDispatch } from 'react-redux';


const texts = {
  title: 'Add New Peptide Source',
}

const Div = ({ scssName, ...props }) => (
  <div {...props} className={props.className ? props.className : _scss[scssName]}>
    {props.children}
  </div>
)

const MuiTableContainer = (props) => (
  <TableContainer component={Paper} variant="outlined" className={"table-container"}>
    <Table {...props} aria-label="a dense table">
      {props.children}
    </Table>
  </TableContainer>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell {...props} style={{ fontSize: 20, width: 190 }} align='left'
    component="th" scope="row" className="bgc-grey50">
    {props.children}
  </StyledTableCell>
)
//---------------------------------------------------------------------------
const PeptideAdd = () => {
  // -----variables-------------------------------------------------------
  const classId = parseInt(cookie.getCookie(cookie.keys.selectedFilterPeptideClsId))

  const [output, setOutput] = useState({}) //all of output
  const [columnNameList, setColumnNameList] = useState([])
  const [rowsArray, setRowsArray] = useState({}) //set row
  const [updatedResultArray, setUpdatedResultArray] = useState([]);//newArray

  const [length, setLength] = useState("");
  const [originMass, setOriginMass] = useState("");
  const [originSequence, setOriginSequence] = useState("");

  const [molWeightIndex, setMolWeightIndex] = useState(0);

  const [info, setInfo] = useState(snackInfo.init())
  const [isLoading, setIsLoading] = useState(true)
  const [numResultError, setResultError] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isResultCode200, setResultCode200] = useState(false)

  const [open, setOpen] = useState(false);
  const [isSequenceValid, setIsSequenceValid] = useState(true);
  const [isMassValid, setIsMassValid] = useState(true);

  const navigate = useNavigate()
  const dispatch = useDispatch();
  // -----functions-------------------------------------------------------
  const eventResult = () => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.showPeptideEdit,
      data: {
        "class_id": classId,
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          let output = result.data.modify_page_info
          setOutput(output)

          const newArray = [];
          const columnName = [];
          for (let i = 0; i < output.modify_page_info.length; i++) {
            const newObj = {
              index: `(${i + 1})`,name: output.modify_page_info[i], value: ""
            };
            columnName.push(output.modify_page_info[i])
            newArray.push(newObj);
          }
          let lengthIndex = columnName.indexOf('Peptide Length');
          let massIndex = columnName.indexOf('Peptide Mass');
          let molWeightIndex = columnName.indexOf('Mol Weight');

          setRowsArray(newArray)
          setUpdatedResultArray(newArray)
          setColumnNameList(columnName)
          setMolWeightIndex(molWeightIndex)

          setLength(0);

          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
    })
  }
  const saveEditResult = () => {
    const valuesArray = updatedResultArray.map(item => item.value);
    const dbObject = {};
    for (let i = 0; i < output.DB_column_name.length; i++) {
      dbObject[output.DB_column_name[i]] = valuesArray[i];
    }
    // console.log('11dbObject', dbObject,'classId',classId)

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.peptideAdd,
      data: {
        "class_id": classId,
        "add_info": dbObject
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          setInfo(snackInfo.open(snackType.success, result.data.message))
          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
    })
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    navigate(paths.site.proteinPeptideCls)
    closeDialog();
  };


  const handleValueChange = (index, newValue, displayName) => {
    let updatedArray = [...updatedResultArray];
    let getIndex = columnNameList.indexOf(displayName);
    let lengthIndex = columnNameList.indexOf('Peptide Length');
    if (index < molWeightIndex || displayName !=='FunctionCount') {
      updatedArray[index].value = removeTag(newValue)
    } else {
      updatedArray[index].value = parseFloat(newValue)
    }
    if (displayName === 'SPP_ID') {
      updatedArray[0].value = newValue.toUpperCase()
    }
    if (displayName === 'Peptide Sequence') {
      if (/\d/.test(newValue) || newValue === '') {
        // console.log("letter")
        updatedArray[getIndex].value = originSequence
        setIsSequenceValid(false)
      } else {
        // console.log(originSequence)
        setIsSequenceValid(true)
        updatedArray[lengthIndex].value = sequenceLength(newValue)
        updatedArray[getIndex].value = newValue.toUpperCase()
      }
    }
    if (displayName === 'Peptide Mass') {
      if (/^[0-9]*$/.test(newValue) || newValue === 'N.D') {
        updatedArray[getIndex].value = newValue + " Da"
        setIsMassValid(true)
      } else {
        setIsMassValid(false)
        updatedArray[getIndex].value = originMass
      }
    }
    // console.log('09updatedArray', updatedArray)
  };
  const removeTag = (hasTagValue) => {
    const withoutStartP = hasTagValue.replace(/^<p>/, '');
    const noTag = withoutStartP.replace(/<\/p>$/, '');
    return noTag
  };
  const sequenceLength = (sequence) => {
    const length = sequence.length
    setLength(length)
    return length
  };
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (isResultCode200 === false && isTimeout === false && numResultError < 5)
      eventResult();
  }, [output, updatedResultArray])

  // -----render-------------------------------------------------------
  return (
    <Div className={`${ppsScss["main"]} ${listLayoutScss["main"]}`}>
      <Div className={`${ppsScss["page-title-block"]}  ${listLayoutScss["page-title-block"]}`}>
        <IconButton onClick={() => { navigate(-1) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
        {texts.title}
      </Div>

      <MuiTableContainer size='small'>
        <TableBody >
          {isLoading ? <LoadingAnime /> :
            <TableCell>
              {rowsArray.map((item, index) => (
                <TableRow key={index} className={_scss["table-raw"]}>
                  <MuiTableHeadCell1st>{item.index} {item.name}</MuiTableHeadCell1st>
                  <Div style={{ margin: '10px' }}>
                    {item.name === "SPP_ID" || item.name === "Term" || item.name === "Precursor Protein" || item.name === "Pubdate" ? (
                      <TextField
                        multiline
                        variant="outlined"
                        className={_scss['row-textfield']}
                        defaultValue={item.value}
                        onChange={(event) => handleValueChange(index, event.target.value, item.name)}
                        label={item.name}
                        required
                      />) 
                      : (item.name === "Peptide Sequence") ? (
                        <TextField
                          multiline
                          variant="outlined"
                          className={_scss['row-textfield']}
                          defaultValue={item.value}
                          onChange={(event) => handleValueChange(index, event.target.value, item.name)}
                          label={item.name}
                          required
                          error={!isSequenceValid}
                          helperText="only letters(a-z) are allowed"
                        />) 
                        : (item.name === "Peptide Mass") ? (
                          <TextField
                            multiline
                            variant="outlined"
                            className={_scss['row-textfield']}
                            defaultValue={item.value}
                            onChange={(event) => handleValueChange(index, event.target.value, item.name)}
                            label={item.name}
                            required
                            error={!isMassValid}
                            helperText="only number or N.D are allowed"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">Da</InputAdornment>,
                            }}
                          />)
                      : (item.name === "Peptide Length") ? (
                        <Div scssName={'item-text'}>{length}</Div>
                      )                              
                      : (item.name === "FunctionCount") ? (
                        <TextField
                          multiline
                          variant="outlined"
                          className={_scss['row-textfield']}
                          defaultValue={item.value}
                          onChange={(event) => handleValueChange(index, event.target.value, item.name)}
                          label={item.name}
                          required
                        />
                      )
                        : (index >= molWeightIndex) ? (
                          <TextField
                            multiline
                            variant="outlined"
                            className={_scss['row-textfield']}
                            defaultValue={item.value}
                            onChange={(event) => handleValueChange(index, event.target.value, item.name)}
                            label={item.name}
                            required
                          />
                        )
                        : (
                          <div className={_scss["table-raw"]}>
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                              }}
                              onReady={editor => {
                                editor.ui.view.editable.element.style.height = '200px';
                              }}
                              data={item.value}
                              onChange={(event, editor) => {
                                const newData = editor.getData();
                                handleValueChange(index, newData);
                              }}
                              style={{ width: '100%', margin: '10px' }}
                            />
                          </div>
                        )}

                  </Div>

                </TableRow>))
              }
            </TableCell>
          }
        </TableBody>
      </MuiTableContainer>

      {/* button */}
      <Box scssName={'button'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" color="grey" onClick={openDialog}>
          Cancel
        </Button>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: '#4d7dae' }}
          onClick={saveEditResult}>
          Save
        </Button>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have unsaved changes. Do you want to save them?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: '#FF2D2DB2' }}
              onClick={() => {
                navigate(paths.site.proteinPeptideCls)
              }} >Leave
            </Button>
            <Button onClick={() => {
              handleConfirm();
              saveEditResult();
            }}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />

    </Div>
  )
}
export default PeptideAdd