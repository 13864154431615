// -----official tools & third tools-------------------------------------------------------
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { dialogMaxWidth } from "utils/generalVar";

import PropTypes from 'prop-types';

// -----custom tools-------------------------------------------------------
import dcdScss from "assets/scss/components/DeleteConfirmDialog.module.scss";

//---------------------------------------------------------------------------
const DeleteConfirmDialog = (props) => {
  // -----variables-------------------------------------------------------

  // -----functions-------------------------------------------------------

  useEffect(() => {
    if (props.isYesBtnDisable === null || props.isYesBtnDisable === undefined) {
      props.isYesBtnDisable = false
    }
  })

  // -----render-------------------------------------------------------
  return (<Dialog
    open={props.isOpen}
    onClose={props.handleClose}
    aria-labelledby="delete-confirm-dialog"
    aria-describedby=""
    PaperProps={dialogMaxWidth}
  >
    <DialogTitle id="delete-confirm-dialog-title">
      {props.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="delete-confirm-description">
        {props.content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} className={dcdScss["confirm-btn"]} >Cancel</Button>
      <Button onClick={props.handleConfirmAction} className={dcdScss["delete-btn"]} disabled={props.isYesBtnDisable}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>)
};

DeleteConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmAction: PropTypes.func.isRequired,
  isYesBtnDisable: PropTypes.bool
};
export default DeleteConfirmDialog;