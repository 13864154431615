// -----official tools & third tools-------------------------------------------------------
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase } from "@mui/material";


// -----custom tools-------------------------------------------------------
import psb from "assets/scss/components/ProteinSearchBar.module.scss";


//---------------------------------------------------------------------------
const ProteinSearchBar = (props) => {
    // -----variables-------------------------------------------------------


    // -----functions-------------------------------------------------------
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.handleClickSearch();
        }
    }

    // -----render-------------------------------------------------------
    return (<div className={psb["search-text"]}>
        <InputBase className='b2-grey100'
            sx={{ m: 0, flex: 1 }}
            placeholder={props.searchHint}
            value={props.keyword}
            onChange={(event) => { props.handleChangeSearch(event.target.value) }}
            onKeyDown={handleKeyDown}
        />
        <IconButton type="button" sx={{ padding: '0px' }} aria-label="search" onClick={props.handleClickSearch}>
            <SearchIcon />
        </IconButton>
    </div>)
};

export default ProteinSearchBar;