const auth = {
    login: '/login',
}

const site = {
    home: '/',
    proteinPeptideCls: '/proteinPeptideCls',
    proteinPeptideSettings: '/proteinPeptideSettings',
    proteinAdd:'/proteinAdd',
    proteinEdit:'/proteinEdit',
    accountManagement:'/accountManagement',
    //peptide
    peptideEdit:'/peptideEdit',
    peptideAdd:'/peptideAdd',
    peptideIntro:'/peptideIntro',
    peptideIntroEdit:'/peptideIntroEdit',
    peptideClsAdd:'/peptideClsAdd'
}

const paths = {
    auth,
    site,
  }
  export default paths
  