// -----official tools & third tools-------------------------------------------------------
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// -----custom tools-------------------------------------------------------
import pceadScss from "assets/scss/components/Protein/ProteinClsEditAddDialog.module.scss";
import { snackBarObj, snackType } from 'components/SnackBar';
import { setSnackbarInfo } from "store/mainSlice";
import { handleKeyDown } from 'utils/generalVar';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";

//---------------------------------------------------------------------------
const ProteinSourceEditAddDialog = (props) => {
  // -----variables-------------------------------------------------------
  const [newProteinSourceId, setNewProteinSourceId] = useState("");
  const [newProteinSourceName, setNewProteinSourceName] = useState("");
  const [isOpenDoubleConfirmDialog, setIsOpenDoubleConfirmDialog] = useState(false);

  const [selectedCls, setSelectedCls] = useState("")

  const [isLoadingEditSource, setIsLoadingEditSource] = useState(false)
  const [isLoadingAddSource, setIsLoadingAddSource] = useState(false)

  const dispatch = useDispatch()


  // -----functions-------------------------------------------------------
  useEffect(() => {
    if (props.isAddMode) {
      setNewProteinSourceName("")
      setNewProteinSourceId("")
    } else {
      setNewProteinSourceName(props.nameValue)
      setNewProteinSourceId(props.sourceId)
    }

    setSelectedCls(props.selectedClsName)

    return () => {
    };
  }, [props.isOpen]);


  const cancelAction = () => {
    if (newProteinSourceName !== "" || selectedCls !== "" || newProteinSourceId !== "") {
      // setIsOpenDoubleConfirmDialog(true)
      props.handleCancelAction()
    } else {
      props.handleCancelAction()
    }
  }

  const editAddAction = () => {
    if (props.isAddMode) {
      addProteinSource()
    } else {
      editProteinSource()
    }
  }

  const editProteinSource = () => {
    setIsLoadingEditSource(true)

    let sendData = {
      "type": 2,
      "source_id": Number(props.sourceId),
      "cls_id": Number(props.clsObj[selectedCls].cls_id),
      "name": newProteinSourceName
    }

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.editProteinClsSource,
      data: sendData
    }))
      .then(result => {
        setIsLoadingEditSource(false)
        switch (result.data.result_code) {
          case 200:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
            props.handleSaveAction(newProteinSourceName, Number(props.sourceId), selectedCls, props.oldSelectedClsName, props.oldSelectedSourceName, props.sourceCountId)
            break;
          default:
        }

      }).catch(err => {
        setIsLoadingEditSource(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const addProteinSource = () => {
    setIsLoadingAddSource(true)

    let sendData = {
      "type": 2,
      "code_c": Number(newProteinSourceId),
      "code_p": Number(props.clsObj[selectedCls].cls_id),
      "count_id": String(props.sourceCountId),
      "name": newProteinSourceName
    }
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.addProteinClsSource,
      data: sendData
    }))
      .then(result => {
        setIsLoadingAddSource(false)
        switch (result.data.result_code) {
          case 200:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Create successfully" })));
            props.handleSaveAction(newProteinSourceName, Number(newProteinSourceId), selectedCls, props.oldSelectedClsName, props.oldSelectedSourceName, props.sourceCountId)
            break;
          default:
        }

      }).catch(err => {
        setIsLoadingAddSource(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  // -----render-------------------------------------------------------
  return (<Dialog
    open={props.isOpen}
    onClose={cancelAction}
    aria-labelledby="protein-edit-add-dialog"
    aria-describedby=""
  >
    <DialogTitle id="protein-edit-add-dialog-title">
      {props.title}
    </DialogTitle>
    <DialogContent className={pceadScss["content"]}>
      <TextField
        label="ID"
        id="protein-count-id-size-small"
        size="small"
        value={props.isAddMode ? props.sourceCountId : props.selectedSourceCountId}
        disabled={true}
      />

      <TextField
        label="Code_C"
        id="protein-classification-id-size-small"
        size="small"
        type="number"
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        value={newProteinSourceId}
        onChange={(event) => {
          setNewProteinSourceId(Number(event.target.value))
        }}
        disabled={!props.isAddMode}
      />

      <FormControl size="small">
        <InputLabel id="cls-dropdown-label">Classification</InputLabel>
        <Select
          labelId="cls-dropdown-label"
          value={selectedCls}
          label="Classification"
          onChange={(event) => {
            setSelectedCls(event.target.value)
          }}
        >
          {Object.entries(props.clsObj).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Name"
        id="protein-classification-name-size-small"
        size="small"
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        value={newProteinSourceName}
        onChange={(event) => {
          setNewProteinSourceName(event.target.value)
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction} className={pceadScss["cancel-btn"]} >Cancel</Button>
      <Button onClick={
        editAddAction
        // props.handleSaveAction

      } className={pceadScss["confirm-btn"]}>
        {props.isAddMode ? "Create" : "Save"}
      </Button>
    </DialogActions>

    {/* <DoubleConfirmDialog
      content='Are you sure to cancel this action ?'
      isOpen={isOpenDoubleConfirmDialog}
      handleClose={() => { setIsOpenDoubleConfirmDialog(false) }}
      handleConfirmAction={() => {
        setIsOpenDoubleConfirmDialog(false)
        props.handleCancelAction()
      }}
    /> */}
  </Dialog>)
};

ProteinSourceEditAddDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  handleSaveAction: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  nameValue: PropTypes.string,
  sourceId: PropTypes.number,
  clsObj: PropTypes.object,
  oldSelectedClsName: PropTypes.string,
  oldSelectedSourceName: PropTypes.string,
};
export default ProteinSourceEditAddDialog;