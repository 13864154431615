// -----official tools & third tools-------------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// -----custom tools-------------------------------------------------------
import pceadScss from "assets/scss/components/Protein/ProteinClsEditAddDialog.module.scss";
import DoubleConfirmDialog from 'components/DoubleConfirmDialog';
import { snackBarObj, snackType } from 'components/SnackBar';
import { setSnackbarInfo } from "store/mainSlice";
import { dialogMaxWidth, handleKeyDown } from "utils/generalVar";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";


//---------------------------------------------------------------------------
const PeptideClsEditAddDialog = (props) => {
  // -----variables-------------------------------------------------------
  const [newPeptideClsTableName, setNewPeptideClsTableName] = useState("");
  const [newPeptideClsDisplayName, setNewPeptideClsDisplayName] = useState("");
  const [newIdPrefix, setNewIdPrefix] = useState("");
  const [isOpenDoubleConfirmDialog, setIsOpenDoubleConfirmDialog] = useState(false);

  const [isLoadingEditCls, setIsLoadingEditCls] = useState(false);
  const [isLoadingAddCls, setIsLoadingAddCls] = useState(false);

  const defaultColumns = ["spp_id", "peptide_sequence", "precursor_protein", "peptide_length", "peptide_mass", "pubdate", "term"]

  const dbColumnTypes = [
    {
      value: "INT",
      name: "INT"
    },
    {
      value: "DOUBLE(15,7)",
      name: "DOUBLE(15,7)"
    }, {
      value: "VARCHAR(100)",
      name: "VARCHAR(100)"
    }, {
      value: "VARCHAR(200)",
      name: "VARCHAR(200)"
    }, {
      value: "VARCHAR(300)",
      name: "VARCHAR(300)"
    },
    {
      value: "VARCHAR(400)",
      name: "VARCHAR(400)"
    },
    {
      value: "VARCHAR(500)",
      name: "VARCHAR(500)"
    },
    {
      value: "VARCHAR(600)",
      name: "VARCHAR(600)"
    }]

  const [rows, setRows] = useState([
    { columnName: '', selectedOption: dbColumnTypes[0].value }
  ]);

  const dispatch = useDispatch();

  // -----functions-------------------------------------------------------
  useEffect(() => {
    if (props.isAddMode) {
      setNewPeptideClsDisplayName("")
      setNewPeptideClsTableName("")
      setNewIdPrefix("")

      setRows([
        { columnName: '', selectedOption: dbColumnTypes[0].value }
      ])
    } else {
      // Edit Mode
      props.selectedDisplayName !== undefined && setNewPeptideClsDisplayName(props.selectedDisplayName);
      setNewIdPrefix(props.idPrefix)
    }
    return () => {
    };
  }, [props.isOpen]);


  const cancelAction = () => {
    if (newPeptideClsDisplayName !== "" || newPeptideClsTableName !== null) {

      if (props.isAddMode) {
        setIsOpenDoubleConfirmDialog(true)
      } else {
        props.handleCancelAction()
      }
    } else {
      props.handleCancelAction()
    }
  }

  const editProteinCls = () => {
    setIsLoadingEditCls(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.editPeptideCls,
      data: {
        "table_name": props.selectedEditTableName,
        "new_name": newPeptideClsDisplayName
      }
    }))
      .then(result => {
        setIsLoadingEditCls(false)
        switch (result.data.result_code) {
          case 200:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
            props.handleSaveAction(newPeptideClsDisplayName, props.selectedEditTableName)
            break;
          default:
        }

      }).catch(err => {
        setIsLoadingEditCls(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const checkColumnNameValid = (columnName) => {
    if (typeof columnName !== 'string' || columnName.length === 0 || columnName.length > 64 || columnName === "") {
      return false;
    }

    // Regular expression to check if the column name starts with a letter or underscore
    // and contains only letters, numbers, and underscores
    const regex = /^[a-z_][a-zA-Z0-9_]*$/;

    return regex.test(columnName)
  }

  function checkValidTableName(tableName) {
    if (typeof tableName !== 'string' || tableName.length === 0 || tableName.length > 64 || tableName === "") {
      return false;
    }

    // Remove leading and trailing whitespace
    tableName = tableName.trim();

    // Regular expression to check if the table name contains only letters, numbers, underscores, and dollar signs
    const regex = /^[a-zA-Z_]*$/;

    return regex.test(tableName);
  }

  const isAllUpperCase = (str) => /^[A-Z]+$/.test(str);

  const editAddAction = () => {
    console.log('props.isAddMode:',props.isAddMode)
    if (!props.isAddMode) {
      editProteinCls()
    }
  }

  function isValidDbColumnName(name) {
    if (name.length > 64) return false;

    if (name.length === 0) return false;

    if (/\d/.test(name[0])) return false;

    if (/[^a-zA-Z0-9_]/.test(name)) return false;

    return true;
  }

  const handleAddRow = () => {
    setRows([...rows, { columnName: '', selectedOption: dbColumnTypes[0].value }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleColumnNameChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].columnName = event.target.value;
    setRows(newRows);
  };

  const handleSelectChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].selectedOption = event.target.value;
    setRows(newRows);
  };



  // -----render-------------------------------------------------------
  return (<Dialog
    open={props.isOpen}
    onClose={cancelAction}
    aria-labelledby="peptide-edit-add-dialog"
    aria-describedby=""
    PaperProps={dialogMaxWidth}
  >
    <DialogTitle id="protein-edit-add-dialog-title">
      {props.title}
    </DialogTitle>
    <DialogContent className={pceadScss["content"]}>
      {props.isAddMode ?
        <div style={{ display: "flex" }}>
          <span style={{ paddingTop: "10px", paddingRight: "10px" }}>SPP</span>
          <TextField
            label="ID Prefix"
            id="peptide-classification-id-prefix-size-small"
            size="small"
            value={newIdPrefix}
            helperText={props.isAddMode ? "If input is 'ACE', the output will be 'SPPACE'" : ''}
            onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
            onChange={(event) => {
              setNewIdPrefix(event.target.value)
            }}
          />
        </div> :
        <TextField
          label="ID"
          id="peptide-classification-id-prefix-size-small"
          size="small"
          disabled={!props.isAddMode}
          value={newIdPrefix}
        />}

      <TextField
        label="Table Name"
        id="peptide-classification-table-name-size-small"
        size="small"
        value={props.isAddMode ? newPeptideClsTableName : props.selectedEditTableName}
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        onChange={(event) => {
          setNewPeptideClsTableName(event.target.value)
        }}
        disabled={!props.isAddMode}
      />

      <TextField
        label="Display Name"
        id="peptide-classification-display-name-size-small"
        size="small"
        value={newPeptideClsDisplayName}
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        onChange={(event) => {
          setNewPeptideClsDisplayName(event.target.value)
        }}
      />

      {props.isAddMode &&
        <div>
          {rows.map((row, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>

              <TextField
                label="Custom Column"
                size="small"
                value={row.columnName}
                onChange={(event) => handleColumnNameChange(event, index)}
                onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
                style={{ width: "160px", marginRight: "16px" }}
              />

              <TextField
                select
                size="small"
                label="Type"
                value={row.selectedOption}
                onChange={(event) => handleSelectChange(event, index)}
                variant="outlined"
                fullWidth
                style={{ width: "160px" }}
              >
                {dbColumnTypes.map(item => {
                  return <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                })}
              </TextField>


              {rows.length > 1 && <IconButton onClick={() => handleRemoveRow(index)}>
                <RemoveIcon />
              </IconButton>}

              {index === rows.length - 1 && (
                <IconButton onClick={handleAddRow}>
                  <AddIcon />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      }

      {props.isAddMode &&
        <div>
          <Typography>Default Columns in DB</Typography>

          <Typography paragraph className={pceadScss["default-column-names"]}>{defaultColumns.join(", ")}</Typography>
        </div>
      }

    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction} className={pceadScss["cancel-btn"]} >Cancel</Button>
      <Button
        disabled={isLoadingEditCls || isLoadingAddCls}
        onClick={editAddAction}
        className={pceadScss["confirm-btn"]}>
        {props.isAddMode ? isLoadingAddCls ? "Creating" : "Create" : isLoadingEditCls ? "Updating" : "Save"}
      </Button>
    </DialogActions>

    <DoubleConfirmDialog
      content='Are you sure to cancel this action ?'
      isOpen={isOpenDoubleConfirmDialog}
      handleClose={() => { setIsOpenDoubleConfirmDialog(false) }}
      handleConfirmAction={() => {
        setIsOpenDoubleConfirmDialog(false)
        props.handleCancelAction()
      }}
    />
  </Dialog >)
};

PeptideClsEditAddDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  handleSaveAction: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  nameValue: PropTypes.string,
  clsId: PropTypes.number,
};
export default PeptideClsEditAddDialog;