// -----official tools & third tools-------------------------------------------------------
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useRef, useState } from "react";

// -----custom tools-------------------------------------------------------
import SearchResultTable from "components/ListTable";
import PaginationBlock from "components/PaginationBlock";
import ProteinSearchBar from "components/Protein/ProteinSearchBar";
import { snackBarObj, snackType } from 'components/SnackBar';
import { PEPTIDE } from 'components/Topbar';
import ListLayout from "layout/ListLayout";
import { useDispatch } from 'react-redux';
import { setSnackbarInfo } from 'store/mainSlice';
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import apiParams, { NumberPerPage } from "utils/network/apiParams";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import { ROLE } from 'utils/role';
import { checkOnlyCharAndNumber, invalidChar } from 'utils/generalVar';

//---------------------------------------------------------------------------
const PeptideList = () => {
	// -----variables-------------------------------------------------------
	const searchHint = 'Search by SPP ID'

	const [peptideCls, setPeptideCls] = useState([]);
	const [peptideData, setPeptideData] = useState([]);
	const [peptideHeaders, setPeptideHeaders] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [itemNo, setItemNo] = useState({
		start: 1,
		end: 10,
	}) //item index 1-10

	const [pageNo, setPageNo] = useState({
		now: 1,
		last: 1,
	})
	const [itemsPerPage, setItemsPerPage] = useState(NumberPerPage) //item number per page

	const PREVIOUS = "Previous"
	const OMIT = "..."
	const NEXT = "Next"
	let words = {
		// index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
		page: [PREVIOUS, OMIT, NEXT],
	}

	const [pages, setPages] = useState([])
	const [keyword, setKeyword] = useState('')

	const [selectedFilterCls, setSelectedFilterCls] = useState("")
	const [selectedFilterClsId, setSelectedFilterClsId] = useState(0)


	const [totalAmount, setTotalAmount] = useState(null);

	const isFirstRender = useRef(true);

	const [goToValue, setGoToValue] = useState("");

	const dispatch = useDispatch()

	// -----functions-------------------------------------------------------

	// const getPeptideList = ({ filterClsId = 0 }) => {

	// 	let sendData = {};

	// 	if (keyword !== "") {
	// 		sendData["keyWord"] = keyword
	// 	}

	// 	if (filterClsId === 0) {
	// 		filterClsId = selectedFilterCls
	// 	}

	// 	sendData["cls_id"] = filterClsId

	// 	setIsLoading(true)
	// 	_axios(apiPostConfig({
	// 		endpoint: apiEndPoint.site.proteinsSearch,
	// 		data: apiParams.peptide.search(sendData)
	// 	}))
	// 		.then(result => {
	// 			setIsLoading(false)
	// 			const data = result.data;
	// 			switch (data.result_code) {
	// 				case 200:
	// 					const rowDataList = data["item(s)"];
	// 					// Set Row Data
	// 					setPeptideData(rowDataList)
	// 					setTotalAmount(data["total"])

	// 					// Set Table Headers
	// 					handleCustomHeaders(rowDataList)

	// 					let perPageTotalData = data["item(s)"].length
	// 					let totalPage = Math.ceil(data.total / itemsPerPage)
	// 					setPageNo({ now: 1, last: totalPage })
	// 					updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })

	// 					break;
	// 				default:
	// 				// handleErrMsg(data.reason, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
	// 			}

	// 		}).catch(err => {
	// 			setIsLoading(false)
	// 			handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
	// 		})
	// }


	useEffect(() => {
		// getProteinClsSourceList() // get protein classification list & source list
		getAllPeptide()
		return () => {
		};
	}, []);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		eventResult();
	}, [pageNo.now]);


	const setExceptIndex = (indexNames) => {
		return indexNames.map(indexName =>
			peptideHeaders.findIndex(x => x.name === indexName)
		);
	}

	const setAlignCenterIndexes = (indexNames) => {
		return indexNames.map(indexName =>
			peptideHeaders.findIndex(x => x.name === indexName)
		);
	}

	const eventResult = () => {
		setIsLoading(true)
		let endpoint = apiEndPoint.site.peptideSearch;

		let inputValue = {
			currentPage: pageNo.now,
			lineSize: itemsPerPage,
		}

		if (keyword !== "") {
			inputValue.keyWord = keyword
		}

		if (selectedFilterClsId !== 31) {
			// Not Multi peptide
			inputValue.cls_id = selectedFilterClsId
		} else {
			// Multi peptide
			endpoint = apiEndPoint.site.multiPeptideSearch;
		}

		_axios(apiPostConfig({
			endpoint: endpoint,
			data: apiParams.peptide.search(inputValue)

		}))
			.then(result => {
				const data = result.data;
				setIsLoading(false)
				switch (data.result_code) {
					case 200:
						const rowDataList = data["item(s)"];

						setPeptideData(rowDataList)
						setTotalAmount(data["total"])

						// Set Table Headers
						handleCustomHeaders(rowDataList)

						let perPageTotalData = data["item(s)"].length
						let totalPage = Math.ceil(data.total / itemsPerPage)
						setPageNo({ now: pageNo.now, last: totalPage })
						updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })
						break;
					default:
					// setInfo(snackInfo.open(snackType.error, result.data.message))
				}

			}).catch(err => {
				setIsLoading(false)
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const handleClickPage = (value) => {
		if (value < 1 || value > pageNo.last) {
			setGoToValue("")
			dispatch(setSnackbarInfo({ isOpen: true, type: snackType.error, msg: "Wrong page number" }));
		} else {
			let pageNew = pageNo.now
			if (value === NEXT) { //Next
				if ((pageNew + 1) > pageNo.last)
					return;
				pageNew += 1
			}
			else if (value === PREVIOUS) //Previous
			{
				if ((pageNew - 1) < 1)
					return;
				pageNew -= 1
			}
			else if (value === OMIT) {
				//...
				return;
			}
			else {
				let num = Number(value)
				if (num !== undefined) {
					pageNew = num
				}
				else {
					return;
				}
			}
			setPageNo({ now: pageNew, last: pageNo.last })
		}


	}

	const updatePages = ({ perPageTotalData, totalData }) => {

		let itemStart = ((pageNo.now - 1) * itemsPerPage)
		if (perPageTotalData > 0 && perPageTotalData < itemsPerPage)
			setItemNo({ start: itemStart + 1, end: itemStart + perPageTotalData })
		else if (perPageTotalData === 0)
			setItemNo({ start: 0, end: 0 })
		else
			setItemNo({ start: itemStart + 1, end: itemStart + itemsPerPage })

		let totalPage = Math.ceil(totalData / itemsPerPage)

		let pages = []
		if (totalPage <= 5 && totalData !== 0) {
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous

			for (let index = 1; index <= totalPage; index++) {
				pages.push(`${index}`)
			}

			if (pageNo.now < totalPage)
				pages.push(NEXT) // Next
		} else {
			let pageStart = ((pageNo.now + 2) > totalPage ? totalPage - 2 : pageNo.now - 1) //start index on 3 button of center
			if (pageStart < 1)
				pageStart = 1
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous
			if (pageNo.now >= 3) {
				pages.push(1)
				if (pageNo.now > 3) //1 ... 3 4 5
					pages.push(OMIT) //...
			}
			let pageFirst3 = (totalPage > 3 ? 3 : totalPage)
			for (var i = pageStart; i < (pageStart + pageFirst3); i++)
				pages.push(String(i))
			if (pageNo.now <= (totalPage - 2)) {
				if (pageNo.now < (totalPage - 2)) //12 13 14 ... 16
					pages.push(OMIT) //...
				pages.push(totalPage)
			}
			if (pageNo.now < totalPage)
				pages.push(NEXT) //Next
		}
		setPages(pages)
	}

	const handleChangeSearch = (value) => {
		setKeyword(value)
	}

	const handleClickSearch = () => {

		if(checkOnlyCharAndNumber(keyword)){
			setPageNo({
				now: 1,
				last: 1,
			})
			if (pageNo.now === 1) {
				eventResult()
			}
		}else{
			dispatch(setSnackbarInfo({ isOpen: true, type: snackType.error, msg: invalidChar }));
		}
	}


	const getAllPeptide = () => {
		_axios(apiGetConfig({
			endpoint: apiEndPoint.site.getAllPeptide
		}))
			.then(result => {
				setIsLoading(false);
				switch (result.data.result_code) {
					case 200:
						const peptideClsList = result.data["peptides"];
						const selectedClsByCookie = cookie.getCookie(cookie.keys.selectedFilterPeptideCls)

						const isClsExist = peptideClsList.some(item => item.display_name === selectedClsByCookie);

						let selectedClsId = 0;

						if (isClsExist) {
							setSelectedFilterCls(selectedClsByCookie)

							let selectedClsList = peptideClsList.filter(item => item.display_name === selectedClsByCookie)

							selectedClsId = selectedClsList[0].cls_id
							setSelectedFilterClsId(selectedClsId)
						} else {
							if (peptideClsList.length !== 0) {
								setSelectedFilterCls(peptideClsList[0].display_name)

								let selectedClsList = peptideClsList.filter(item => item.display_name === peptideClsList[0].display_name)

								selectedClsId = selectedClsList[0].cls_id
								setSelectedFilterClsId(selectedClsId)
							}
						}

						setPeptideCls(peptideClsList)
						getPeptideByCls(selectedClsId)
						break;
					default:
					// setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
				}

			}).catch(err => {
				setIsLoading(false);
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const getPeptideByCls = (selectedClsId) => {
		let sendData = {};
		let endpoint = apiEndPoint.site.peptideSearch;

		if (keyword !== "") {
			sendData["keyWord"] = keyword
		}

		if (selectedClsId !== 31) {
			// Not Multi peptide
			sendData["cls_id"] = selectedClsId;
		} else {
			// Multi peptide
			endpoint = apiEndPoint.site.multiPeptideSearch;
		}


		setIsLoading(true)
		_axios(apiPostConfig({
			endpoint: endpoint,
			data: apiParams.peptide.search(sendData)
		}))
			.then(result => {
				setIsLoading(false)
				const data = result.data;
				switch (data.result_code) {
					case 200:
						const rowDataList = data["item(s)"];

						setPeptideData(rowDataList)
						setTotalAmount(data["total"])

						// Set Table Headers
						handleCustomHeaders(rowDataList)

						let perPageTotalData = data["item(s)"].length
						let totalPage = Math.ceil(data.total / itemsPerPage)
						setPageNo({ now: 1, last: totalPage })
						updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })

						break;
					default:
					// handleErrMsg(data.reason, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
				}

			}).catch(err => {
				setIsLoading(false)
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const handleCustomHeaders = (rowDataList) => {
		// Set Table Headers
		if (rowDataList.length !== 0) {
			const headers = Object.keys(rowDataList[0]).map((key) => {
				let name = key
					.split('_')
					.map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())
					.join(' ');

				function containsIdOrId(input) {
					return /\b(id|Id)\b/.test(input);
				}

				function replaceIdWithID(input) {
					return input.replace(/\b(id|Id)\b/g, 'ID');
				}

				if (containsIdOrId(name)) {
					name = replaceIdWithID(name)
				}

				return {
					name: name,
					apiKey: key,
				};
			});

			let newHeaders = [{ name: "#", apiKey: null }, ...headers]

			const role_id = Number(cookie.getCookie(cookie.keys.roleId));
			if (role_id === ROLE.ROOT || role_id === ROLE.MANAGER) {
				newHeaders = [...newHeaders, { name: "Edit", apiKey: null }]
			}

			setPeptideHeaders(newHeaders)
		}
	}


	const deleteItemForUI = (selectedId) => {
		setPeptideData(peptideData.filter(item => item.SPP_ID !== selectedId))
	}

	// -----render-------------------------------------------------------
	return ListLayout({
		pageTitle: "Peptide",
		addItemText: "Add Peptide",
		totalAmount: totalAmount,
		selectedFilterClsId: selectedFilterClsId,
		keyword:keyword,
		searchBar:
			<ProteinSearchBar
				keyword={keyword}
				searchHint={searchHint}
				handleChangeSearch={handleChangeSearch}
				handleClickSearch={handleClickSearch} />,
		filterBlock: <div style={{ display: "flex", gap: "16px" }}>
			<FormControl size="small">
				<InputLabel id="cls-dropdown-label">Classification</InputLabel>
				<Select
					labelId="cls-dropdown-label"
					value={selectedFilterCls}
					label="Classification"
					style={{ minWidth: '200px' }}
					onChange={(event) => {
						setSelectedFilterCls(event.target.value)
						cookie.setCookie(cookie.keys.selectedFilterPeptideCls, event.target.value)
						let selectedClsList = peptideCls.filter(item => item.display_name === event.target.value)

						cookie.setCookie(cookie.keys.selectedFilterPeptideClsId, selectedClsList[0].cls_id)
						setSelectedFilterClsId(selectedClsList[0].cls_id)

						getPeptideByCls(peptideCls.filter(item => item.display_name === event.target.value)[0]["cls_id"])
					}}
				>
					{peptideCls.map((item) => (
						<MenuItem value={item.display_name} key={item.display_name}>
							{item.display_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

		</div>,
		tableBlock:
			<SearchResultTable
				tableColumnTitles={peptideHeaders}
				searchHint={searchHint}
				isLoading={isLoading}
				items={peptideData}
				itemNo={itemNo}
				type={PEPTIDE}
				keyword={keyword}
				exceptIndexes={setExceptIndex(["#", "Edit"])}
				alignLCenterIndexes={setAlignCenterIndexes(["#", "Edit", "Pubdate","Function counts"])}
				deleteItemForUI={deleteItemForUI}
				noDataColSpan={9}
			/>,
		paginationBlock:
			<PaginationBlock
				pages={pages}
				pageNo={pageNo}
				words={words}
				handleClickPage={handleClickPage}
				goToValue={goToValue}
				onInputChangeInGoTo={(event) => {
					// Replace any non-digit characters with an empty string
					const newValue = event.target.value.replace(/\D/g, '');

					setGoToValue(newValue)
				}} />
	})
};

export default PeptideList;