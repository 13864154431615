// -----official tools & third tools-------------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from "@mui/material";
import { useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v from "assets/scss/_variables.scss";
import listLayoutScss from "assets/scss/layout/ListLayout.module.scss";
import LoadingAnime from 'components/LoadingAnime';
import { snackBarObj, snackType } from 'components/SnackBar';
import { ACCOUNT_MANAGEMENT } from 'components/Topbar';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";
import { ROLE } from 'utils/role';
import paths from 'utils/webPath';


//---------------------------------------------------------------------------
const ListLayout = ({ pageTitle, searchBar, filterBlock, tableBlock, paginationBlock, totalAmount, type, ...props }) => {
  // -----variables-------------------------------------------------------
  const [isDownloading, setIsDownloading] = useState(false)
  const [roleId, setRoleId] = useState(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // -----functions-------------------------------------------------------

  const exportProteinCsv = () => {
    let sendData = {};
    if (props.selectedFilterClsId !== null) {
      sendData["classification"] = props.selectedFilterClsId
    }

    if (props.selectedFilterSourceId !== null) {
      sendData["source"] = props.selectedFilterSourceId
    }

    if (props.keyword !== "") {
      sendData.keyWord = props.keyword
    }

    setIsDownloading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.exportProteinList,
      data: sendData
    }))
      .then(result => {
        setIsDownloading(false)
        const data = result.data;
        switch (data.result_code) {
          case 200:
            window.open(data.link, '_blank');
            break;
          default:
            handleErrMsg(data.reason, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
        }

      }).catch(err => {
        setIsDownloading(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const exportPeptideCsv = () => {
    let sendData = { "cls_id": props.selectedFilterClsId };

    if (props.keyword !== "") {
      sendData.keyWord = props.keyword
    }

    setIsDownloading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.exportPeptideList,
      data: sendData
    }))
      .then(result => {
        setIsDownloading(false)
        const data = result.data;
        switch (data.result_code) {
          case 200:
            window.open(data.link, '_blank');
            break;
          default:
            handleErrMsg(data.reason, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
        }

      }).catch(err => {
        setIsDownloading(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  useEffect(() => {
    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    setRoleId(role_id)
    return () => {
    };
  }, []);

  // -----render-------------------------------------------------------
  return (
    <div className={listLayoutScss["main"]}>
      <div className={listLayoutScss["page-title-block"]}>
        <IconButton onClick={() => { navigate(paths.site.home) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
        {pageTitle} {totalAmount !== null && `(${totalAmount})`}
      </div>


      <div className={listLayoutScss["filter-search-block"]}>
        <div className={listLayoutScss["filter-block"]}>
          {filterBlock}
          {/* <IconButton aria-label="edit" size="small" className={listLayoutScss["add-icon-btn"]} onClick={() => {
            navigate(paths.site.proteinAdd)
          }}>
            <AddIcon fontSize="small" />
          </IconButton> */}
        </div>
        <div className={listLayoutScss["search-bar"]}>
          {searchBar}

          {type !== ACCOUNT_MANAGEMENT && <div className={listLayoutScss["btn-block"]}>

          {totalAmount !== 0 && <IconButton aria-label="download" size="small"
              disabled={isDownloading}
              onClick={() => {

                if (pageTitle === "Protein") {
                  exportProteinCsv()
                }
                if (pageTitle === "Peptide") {
                  exportPeptideCsv()
                }
              }}>
              {isDownloading ? <LoadingAnime size={20} /> : <DownloadIcon fontSize="small" />}
            </IconButton>}

            {((roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && type !== ACCOUNT_MANAGEMENT) &&
              <IconButton aria-label="add" size="small"
                onClick={() => {
                  if (pageTitle === "Protein") {
                    navigate(paths.site.proteinAdd)
                  }
                  if (pageTitle === "Peptide") {
                    navigate(paths.site.peptideAdd)
                  }
                }}>
                <AddIcon fontSize="small" />
              </IconButton>}
          </div>}
        </div>
      </div>
      {tableBlock}

      {paginationBlock}
    </div>)
};

export default ListLayout;