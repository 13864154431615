// import { useState } from "react"
import { Snackbar, Alert } from "@mui/material"

export const snackType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
}

export class snackInfo {
  static create(isOpen, type, msg) {
    return { isOpen, type, msg }
  }
  static open(type, msg) {
    return {
      isOpen: true,
      type: type,
      msg: msg,
    }
  }
  static close(type, msg) {
    return {
      isOpen: false,
      type: type,
      msg: msg,
    }
  }
  static init() {
    return {
      isOpen: false,
      type: snackType.error,
      msg: '',
    }
  }
}

export const snackBarObj = ({isOpen, type, msg}) => {
  return {
    isOpen: isOpen,
    type: type,
    msg: msg,
  }
}

const SnackBar = ({info, ...props}) => {

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={info.isOpen}
      autoHideDuration={3000}
      onClose={props.handleClose}
    >
      <Alert
        onClose={props.handleClose}
        severity={info.type}
        sx={{ width: '100%' }}
        data-cy="register-snackbar">
        {info.msg}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar