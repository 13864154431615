// -----official tools & third tools-------------------------------------------------------
import Paper from '@mui/material/Paper';

// -----custom tools-------------------------------------------------------
import { Table, TableContainer } from "@mui/material";
import tsbScss from "components/Peptide/ToolSingleBlock.module.scss";

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table-container"}>
    <Table aria-label="table" {...props}>
      {props.children} 
    </Table>
  </TableContainer>
)
//---------------------------------------------------------------------------
const ToolSingleBlock = ({ stepText, title, mainBlockContent, anotherBlockContent, ...props }) => {
  // -----variables-------------------------------------------------------

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------
  return (
    <MuiTableContainer>
      <tbody>
        <tr>
          <td>
            <div className={tsbScss["main"]}>
              <div className={tsbScss["left-block"]}>
                <div className={tsbScss["step-text"]}>
                  {stepText}
                </div>
                <div className={tsbScss["title"]}>
                  {title}
                </div>
              </div>
              <div className={tsbScss["right-block"]}>
                <div className={tsbScss["main-block"]}>{mainBlockContent}</div>
                {anotherBlockContent && <div className={tsbScss["main-block"]}>{anotherBlockContent}</div>}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </MuiTableContainer >
  )
};

export default ToolSingleBlock;