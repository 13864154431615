const auth = {
  login: "/v1/admin/login",
};
const site = {
  // Protein Classification & Source Page
  getProteinClsList: "/v1/admin/proteins/class_list",
  updateProteinClsSourceStatus: "/v1/admin/proteins/showContr",
  editProteinClsSource: "/v1/admin/proteins/edit",
  addProteinClsSource: "/v1/admin/proteins/insert",

  // Protein List
  proteinsSearch: "/v1/admin/proteins/search",
  getAllProteinCls: "/v1/admin/proteins/all_class",
  getAllProteinSourceByCls: "/v1/admin/proteins/all_source",
  exportProteinList: "/v1/file/protein_download",

  // Create & Edit Protein
  getProteinsAllClass: "/v1/admin/proteins/all_class",
  deleteProtein: "/v1/admin/proteins/modify_delete",
  showProteinEdit: "/v1/admin/proteins/modify_page",
  proteinEdit: "/v1/admin/proteins/modify_edit",
  proteinAdd: "/v1/admin/proteins/modify_add",

  // Peptide List
  getAllPeptide: "/v1/admin/peptides/all",
  peptideSearch: "/v1/admin/peptides/class_search",
  exportPeptideList:"/v1/file/peptide_download",
  multiPeptideSearch:"/v1/admin/peptides/multifunctional_search",

  // Peptide Classification
  addPeptideCls: "/v1/admin/peptides/insert",
  editPeptideCls: "/v1/admin/peptides/edit",

  // Create & Edit Peptide
  showPeptideEdit: "/v1/admin/peptides/modify_page",
  peptideEdit: "/v1/admin/peptides/modify_edit",
  peptideAdd: "/v1/admin/peptides/modify_add",
  deletePeptide: "/v1/admin/peptides/modify_delete",

  //getProteinsAllClass: "/v1/admin/proteins/all_class",
  getAllUsers: "/v1/user/user_list",
  changeRole: "/v1/user/change_role",
  updateUserStatus: "/v1/user/change_status",

  // Peptide Introduction
  readPeptideIntro: "/v1/admin/peptides/introduction_page",
  editPeptideIntro: "/v1/admin/peptides/edit_introduction",
};

const endpoint = {
  auth,
  site,
};
export default endpoint;
