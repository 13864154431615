import { useDispatch, useSelector } from "react-redux";
// import { BrowserRouter } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { closeSnackbar } from "store/mainSlice";
import Router from "./routes";

import SnackBar from "components/SnackBar";

function App() {
  const snackbarInfo = useSelector((state) => state.main.snackbarInfo);

  const dispatch = useDispatch();

  return (
    <BrowserRouter>
      <div className="App">
        <Router />
        <SnackBar
          info={snackbarInfo}
          handleClose={() => {
            dispatch(closeSnackbar());
          }}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
