// -----official tools & third tools-------------------------------------------------------

import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// -----custom tools-------------------------------------------------------
import { snackBarObj, snackType } from 'components/SnackBar';
import { updateTotalOfPeptideCls } from "store/mainSlice";
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, handleErrMsg } from "utils/network/axios";
import paths from 'utils/webPath';
// import ProteinClsEditAddDialog from './ProteinClsEditAddDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import pcbScss from "assets/scss/components/Peptide/PeptideClsBlock.module.scss";
import { ROLE } from 'utils/role';
import PeptideClsEditAddDialog from './PeptideClsEditAddDialog';

//---------------------------------------------------------------------------
const PeptideClsBlock = () => {
  // -----variables-------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [clsList, setClsList] = useState([]);
  const [isOpenPeptideAddClsDialog, setIsOpenPeptideAddClsDialog] = useState(false);

  const [isAddPeptideMode, setIsAddPeptideMode] = useState(true);
  const [selectedEditTableName, setSelectedEditTableName] = useState("");
  const [selectedDisplayName, setSelectedDisplayName] = useState("");
  const [idPrefix, setIdPrefix] = useState("");

  const [roleId, setRoleId] = useState(null)

  const navigate = useNavigate();

  const clsHeadCells = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      isCenter: true,
      label: '#',
    },
    {
      id: 'table_name',
      numeric: false,
      disablePadding: false,
      isCenter: false,
      label: 'TableName',
    },
    {
      id: 'display_name',
      numeric: false,
      disablePadding: true,
      isCenter: false,
      label: 'Display Name',
    },
    // {
    //   id: 'edit',
    //   numeric: false,
    //   disablePadding: false,
    //   isCenter: true,
    //   label: 'Edit',
    // },
    {
      id: 'intro',
      numeric: false,
      disablePadding: false,
      isCenter: true,
      label: 'Introduction',
    },
  ]

  const dispatch = useDispatch();

  // -----functions-------------------------------------------------------
  useEffect(() => {
    getAllPeptide()

    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    setRoleId(role_id)

    return () => {
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isOpenPeptideAddClsDialog) {
        event.preventDefault();
        event.returnValue = ''; // empty → browser default message
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isOpenPeptideAddClsDialog]);


  const getAllPeptide = () => {
    _axios(apiGetConfig({
      endpoint: apiEndPoint.site.getAllPeptide
    }))
      .then(result => {
        setIsLoading(false);
        setDisableBtn(false)

        switch (result.data.result_code) {
          case 200:

            result.data["peptides"].sort(function (a, b) {
              return a["table_name"].localeCompare(b["table_name"]);
            });

            setClsList(result.data["peptides"]);
            dispatch(updateTotalOfPeptideCls({ value: result.data["peptides"].length }))
            break;
          default:
          // setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoading(false);
        setDisableBtn(true)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }


  // -----components-------------------------------------------------------
  function ClsTableHead(props) {
    return (
      <TableHead>
        <TableRow>
          {clsHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? 'center' : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              className={headCell.label === "TableName" ? pcbScss.customHeaderPadding : ""}
            >
              <b>{headCell.label}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  // -----render-------------------------------------------------------
  return (
    <div style={{ height: "100%" }} className={pcbScss.main}>
      <div className={pcbScss.btn_block}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={pcbScss["btn-title"]}>
            <PeptideClsEditAddDialog
              title={`${isAddPeptideMode ? "Add" : "Edit"} Peptide Classification`}
              isOpen={isOpenPeptideAddClsDialog}
              handleCancelAction={() => {
                setIsOpenPeptideAddClsDialog(false)
              }}
              handleSaveAction={(newPeptideClsDisplayName, newPeptideClsTableName) => {

                if (isAddPeptideMode) {
                  setClsList([...clsList, {
                    "display_name": newPeptideClsDisplayName,
                    "table_name": newPeptideClsTableName
                  }])
                } else {
                  // Edit mode
                  let tmpClsList = clsList;

                  let itemToUpdate = tmpClsList.find(item => {
                    return item.table_name === newPeptideClsTableName
                  });

                  if (itemToUpdate) {
                    itemToUpdate.display_name = newPeptideClsDisplayName;
                  }
                  setClsList(tmpClsList)
                }
                setIsOpenPeptideAddClsDialog(false)
              }}
              isAddMode={isAddPeptideMode}
              selectedEditTableName={selectedEditTableName}
              selectedDisplayName={selectedDisplayName}
              idPrefix={idPrefix}
            />
          </div>
        </div>
        <Box pr={4} />
      </div>
      <Table style={{ tableLayout: 'fixed' }}>
        <ClsTableHead />
      </Table>
      <div style={{ overflow: 'auto', height: 'calc(100% - 113px)' }}>
        <Table
          style={{ tableLayout: 'fixed' }}
        >
          <TableBody >
            {clsList.map((item, key) => {
              const labelId = `table-checkbox-${item.cls_id}`;
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={item.cls_id}
                >
                  <TableCell
                    align="center"
                    component="th">
                    {key + 1}
                  </TableCell>

                  <TableCell align="left">{item.table_name}
                  </TableCell>

                  <TableCell
                    id={labelId}
                    scope="row"
                    padding='none'>
                    <span style={{ minWidth: '200px', display: 'inline-block' }}>{item.display_name}</span>
                    {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) &&
                      <IconButton aria-label="edit" size="small" disabled={disableBtn}
                        title="Edit"
                        onClick={() => {
                          setSelectedEditTableName(item.table_name)
                          setSelectedDisplayName(item.display_name)
                          setIdPrefix(item.SPP_prefix)
                          setIsAddPeptideMode(false)
                          setIsOpenPeptideAddClsDialog(true)
                        }}
                        style={{ marginLeft: '10px' }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    }

                  </TableCell>

                  <TableCell align="center">
                    <IconButton aria-label="edit" size="small" disabled={disableBtn} title="Show Introduction">
                      <ArrowForwardIosIcon fontSize="small"
                        onClick={() => {
                          navigate(paths.site.peptideIntro)
                          cookie.setCookie(cookie.keys.classId, item.cls_id)
                        }} />
                    </IconButton >
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div >)
};


export default PeptideClsBlock;
