// -----official tools & third tools-------------------------------------------------------

// -----custom tools-------------------------------------------------------
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import homeScss from "assets/scss/views/Home.module.scss";
import SelectPeptideClsDialog from 'components/Peptide/SelectPeptideClsDialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cookie from 'utils/cookie';
import { ROLE } from 'utils/role';
import paths from 'utils/webPath';

export const listSelectType = {
  protein: "protein",
  peptide: "peptide"
}

//---------------------------------------------------------------------------
const HomePage = () => {
  // -----variables-------------------------------------------------------

  const [categories, setCategories] = useState({
    "Protein": [{ name: "List Protein", path: paths.site.proteinPeptideCls }, { name: "Add Protein", path: paths.site.proteinAdd }, { name: "Classification & Source", path: paths.site.proteinPeptideSettings }],
    "Peptide": [{ name: "List Peptide", path: paths.site.proteinPeptideCls },
    { name: "Add Peptide", path: paths.site.peptideAdd },
    { name: "Classification", path: paths.site.proteinPeptideSettings }],
  })

  const [isOpenSelectPeptideClsDialog, setIsOpenSelectPeptideClsDialog] = useState(false);
  const [pathOfSelectPeptideClsDialog, setPathOfSelectPeptideClsDialog] = useState("");


  const navigate = useNavigate();
  // -----functions-------------------------------------------------------

  useEffect(() => {
    const roleId = Number(cookie.getCookie(cookie.keys.roleId))

    if (roleId === ROLE.ROOT) {
      setCategories({
        ...categories,
        "Account": [{ name: "Management", path: paths.site.accountManagement }, {}, {}]
      })
    }

    if (roleId !== ROLE.ROOT && roleId !== ROLE.MANAGER) {
      const newCategories = { ...categories };

      Object.keys(newCategories).forEach((key) => {
        newCategories[key] = newCategories[key].filter((item) => {
          return item.name !== "Add Protein" && item.name !== "Add Peptide";
        });
      });

      setCategories(newCategories);
    }


    return () => {
    };
  }, []);


  // -----render-------------------------------------------------------
  return (
    <div className='home'>
      <Grid container className={homeScss["main"]}>
        {Object.keys(categories).map((key) => {
          return <Grid container item xs={3} className={homeScss["each-block"]} key={key}>
            <div className={homeScss["block-title"]}>
              {key}
            </div>

            <Grid container item className={homeScss["feature-items"]}>
              {
                categories[key].map(item => {
                  return <Grid item xs={12} className={homeScss["feature-item"]} key={item.name}>
                    <Button onClick={() => {

                      if (item.path === paths.site.proteinPeptideCls) {
                        if (key === "Protein") {
                          cookie.setCookie(cookie.keys.selectedFilterProteinSourceId, "")
                          cookie.setCookie(cookie.keys.listSelectType, listSelectType.protein)
                        }
                        if (key === "Peptide") {
                          cookie.setCookie(cookie.keys.listSelectType, listSelectType.peptide)
                        }
                      }

                      if (item.path === paths.site.proteinPeptideSettings) {
                        if (key === "Protein") {
                          cookie.setCookie(cookie.keys.selectedSettingType, listSelectType.protein)
                        }
                        if (key === "Peptide") {
                          cookie.setCookie(cookie.keys.selectedSettingType, listSelectType.peptide)
                        }
                      }

                      if (item.name === "Add Peptide") {
                        setIsOpenSelectPeptideClsDialog(true)
                        setPathOfSelectPeptideClsDialog(item.path)
                      } else {
                        navigate(item.path)
                      }
                    }}>{item.name}</Button>
                  </Grid>
                })
              }
            </Grid>
          </Grid>

        })}

        <SelectPeptideClsDialog
          isOpen={isOpenSelectPeptideClsDialog}
          path={pathOfSelectPeptideClsDialog}
          handleCancelAction={() => {
            setIsOpenSelectPeptideClsDialog(false)
          }} />
      </Grid>
    </div>);
};

export default HomePage;
