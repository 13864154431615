// import React from 'react'

//---------------------------------------------------------------------------
export const TagDiv = (props) => {
  const {html, ...other} = props
  return (<div {...other} dangerouslySetInnerHTML={{ __html: html }} />)
}

export const TagText = (props) => {
  const {html, ...other} = props
  return (<font {...other} dangerouslySetInnerHTML={{ __html: html }} />)
}