import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

// import scssCommon from 'assets/scss/common.scss';
import listTableScss from 'assets/scss/components/ListTable.module.scss';
import "assets/scss/general.scss";
import 'assets/scss/index.scss';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, TableHeadRow } from 'components/MuiTable';

import { TagText } from 'components/MuiDiv';
import { snackBarObj, snackType } from 'components/SnackBar';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSnackbarInfo } from "store/mainSlice";
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";
import { ROLE } from 'utils/role';
import paths from 'utils/webPath';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import { ACCOUNT_MANAGEMENT, PEPTIDE, PROTEIN } from './Topbar';

const getSppID = (data) => {
  cookie.setCookie(cookie.keys.dfbpId, data)
  console.log('succeed', data)
};
//---------------------------------------------------------------------------
const SearchResultTable = ({ noDataColSpan = 5, ...props }) => {
  let texts = {
    menus: [
      'Show up to',
    ],
  }
  const itemsPerPageList = [10, 50, 100,]
  itemsPerPageList.map((number) => (texts.menus.push(`${number} items`)))

  function containsID(input) {
    return /\b(id|Id|ID)\b/.test(input);
  }

  const TableBodyCell = ({ item, columTitles, exceptIndexes }) => {
    return (
      columTitles.map((cell, index) =>
        exceptIndexes.includes(index) ? null : (<TableCell
          align={containsLengthOrDate(cell.name) ? "center" : "left"}
          key={index} style={{ minWidth: '150px' }}>

          <TagText html={item[cell.apiKey]} />

          {/* {
            item[cell.apiKey].split(new RegExp(`(${props.keyword})`, 'gi')).map((part, index) =>
              part.toLowerCase() === props.keyword.toLowerCase() ? <span key={index} style={{ backgroundColor: 'yellow', display: 'inline' }}>{part}</span> : part
            )} */}
        </TableCell>)
      )
    )
  }

  function containsLengthOrDate(input) {
    return /\b(length|date|Length|Date|Pubdate|Function counts)\b/.test(input);
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedSppId, setSelectedSppId] = useState("");
  const [isOpenDelConfirmDialog, setIsOpenDelConfirmDialog] = useState(false)

  const [isLoadingDel, setIsLoadingDel] = useState(false)

  const [roleId, setRoleId] = useState(null)

  const axiosDeleteAction = (type) => {
    let endpoint = apiEndPoint.site.deleteProtein
    if (type !== "protein") {
      endpoint = apiEndPoint.site.deletePeptide
    }

    setIsLoadingDel(true)
    _axios(apiPostConfig({
      endpoint: endpoint,
      data: {
        "SPP_ID": selectedSppId,
        "is_del": true
      }
    }))
      .then(result => {
        setIsLoadingDel(false)
        switch (result.data.result_code) {
          case 200:
            props.deleteItemForUI(selectedSppId)

            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Delete successfully" })));
            break;
          default:
        }

      }).catch(err => {
        setIsLoadingDel(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  useEffect(() => {
    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    setRoleId(role_id)
    return () => {
    };
  }, []);


  return (
    <div className={'frame-table'}>
      <TableContainer component={Paper} variant="outlined"
        style={{ maxWidth: '2500px', overflowX: 'auto' }}
      // className={scssCommon["table-container"]}
      >
        {
          props.isLoading
            ? <LoadingAnime /> :
            <Table size="small">
              <TableHeadRow>
                {props.tableColumnTitles.map((cell, index) => (
                  <MuiTableCell key={index} align={props.alignLCenterIndexes.includes(index) || containsLengthOrDate(cell.name) ? "center" : "left"}>{cell.name}</MuiTableCell>
                ))}
              </TableHeadRow>
              <TableBody className={listTableScss["table-row"]}>
                {props.items.length === 0 ?
                  <TableRow>
                    <TableCell component="th" scope="row" align="center" colSpan={noDataColSpan} sx={{ pt: 3, pb: 3, fontSize: 16 }}>
                      There is no matching data
                    </TableCell>
                  </TableRow> : props.items.map((item, index) => (
                    <TableRow
                      key={item.SPP_ID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {index + props.itemNo.start}
                      </TableCell>

                      {TableBodyCell({ item: item, columTitles: props.tableColumnTitles, exceptIndexes: props.exceptIndexes })}

                      {
                        ((roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && props.type !== ACCOUNT_MANAGEMENT) && <TableCell align="center" sx={{ minWidth: "100px" }}>
                          <IconButton aria-label="edit" size="small" onClick={() => {
                            if (props.type === PROTEIN) {
                              navigate(paths.site.proteinEdit)
                              getSppID(item.SPP_ID);
                            }

                            if (props.type === PEPTIDE) {
                              navigate(paths.site.peptideEdit)
                              getSppID(item.SPP_ID);
                            }
                          }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {/* <IconButton aria-label="delete" size="small" sx={{ color: v.error }} onClick={() => {

                        let id = "";

                        if (props.type === PROTEIN) {
                          id = item.SPP_ID
                        }

                        if (props.type === PEPTIDE) {
                          id = item.SPP_ID
                        }

                        setSelectedSppId(id)
                        setIsOpenDelConfirmDialog(true)
                      }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton> */}
                        </TableCell>
                      }

                    </TableRow>
                  ))}
              </TableBody>
            </Table>}

        <DeleteConfirmDialog
          title={`Delete ${props.type === PROTEIN ? "Protein" : "Peptide"}`}
          content={`Are you sure to delete ${selectedSppId}?`}
          isOpen={isOpenDelConfirmDialog}
          isYesBtnDisable={isLoadingDel}
          handleClose={() => {
            setIsOpenDelConfirmDialog(false)
          }}
          handleConfirmAction={async () => {

            if (props.type === PROTEIN) {
              await axiosDeleteAction("protein")
            }

            if (props.type === PEPTIDE) {
              await axiosDeleteAction("peptide")
            }


            setIsOpenDelConfirmDialog(false)
          }} />
      </TableContainer>
    </div>
  )
}
export default SearchResultTable