// -----official tools & third tools-------------------------------------------------------
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import listLayoutScss from "assets/scss/layout/ListLayout.module.scss";
import 'assets/scss/temp.scss';
import ppsScss from "assets/scss/views/ProteinPeptideSettings.module.scss";
import _scss from 'layout/Crud.module.scss';

import LoadingAnime from 'components/LoadingAnime';
import { snackBarObj, snackInfo, snackType } from 'components/SnackBar';
import { useDispatch } from 'react-redux';
import { setSnackbarInfo } from "store/mainSlice";
import StyledTableCell from 'styledComponents/StyledTableCell';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import paths from 'utils/webPath';
//---------------------------------------------------------------------------

const texts = {
  title: 'Add New Protein Source',
}

const Div = ({ scssName, ...props }) => (
  <div {...props} className={props.className ? props.className : _scss[scssName]}>
    {props.children}
  </div>
)

const MuiTableContainer = (props) => (
  <TableContainer component={Paper} variant="outlined" className={"table-container"}>
    <Table {...props} aria-label="a dense table">
      {props.children}
    </Table>
  </TableContainer>
)

// const MuiTableCell = (props) => (
//   <StyledTableCell {...props} style={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
//     {props.children}
//   </StyledTableCell>
// )
const MuiTableHeadCell1st = (props) => (
  <StyledTableCell {...props} style={{ fontSize: 20, width: 190 }} align='left'
    component="th" scope="row" className="bgc-grey50">
    {props.children}
  </StyledTableCell>
)
const MuiSelect = ({ scssName, ...props }) => (
  <Select variant="standard"
    className={_scss[scssName]} style={{ color: v.darkGreen, width: '100%', margin: '20px 0' }}
    {...props}>
    <MenuItem value="" className=''><em>None</em></MenuItem>
    {props.children}
  </Select>
)
const ProteinAdd = () => {
  // -----variables-------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [rowsArray, setRowsArray] = useState([]) //set row 
  const [updatedResultArray, setUpdatedResultArray] = useState([]);//newArray

  const [classCode, setClassCode] = useState([])
  const [proteinCode, setProteinCode] = useState([])

  const [codeC, setCodeC] = useState("");
  const [codeP, setCodeP] = useState("");

  const [originSequence, setOriginSequence] = useState("");
  const [length, setLength] = useState(0);
  const [classification, setClassification] = useState("N.D");

  const [info, setInfo] = useState(snackInfo.init())
  const [isLoading, setIsLoading] = useState(true)
  const [numResultError, setResultError] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isResultCode200, setResultCode200] = useState(false)
  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const [isSequenceValid, setIsSequenceValid] = useState(true);

  const dispatch = useDispatch();
  // -----functions-------------------------------------------------------
  const saveCodeP = (event) => {
    const newValue = event.target.value.toString();
    setCodeP(newValue);
    handleValueChange(10, newValue)
  }
  const saveCodeC = (event) => {
    const newValue = event.target.value.toString();
    setCodeC(newValue);
    handleValueChange(9, newValue)
  }
  const getCodeP = () => {
    setIsLoading(true)
    _axios(apiGetConfig({
      endpoint: apiEndPoint.site.getProteinsAllClass
    }))
      .then(result => {
        // console.log('QS result:', result)
        setIsLoading(false);
        switch (result.data.result_code) {
          case 200:
            let classCode = result.data.classes
            setClassCode(classCode)
            break;
          default:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: result.data.message })));
          // setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoading(false);
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }
  const getCodeC = (str) => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.getAllProteinSourceByCls,
      data: {
        "ids": [str]
      }
    }))
      .then(result => {
        // console.log('QS result:', result)
        setIsLoading(false);
        switch (result.data.result_code) {
          case 200:
            let code = result.data.sources
            setProteinCode(code)
            break;
          default:
          // setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoading(false);
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const eventResult = () => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.showProteinEdit,
      data: {
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          let output = result.data.modify_page_info
          setOutput(output)
          const newArray = [];
          for (let i = 0; i < output.modify_page_info.length; i++) {
            const newObj = {
              index: `(${i + 1})`, name: output.modify_page_info[i], value: output.column_value[i]
            };
            newArray.push(newObj);
          }
          setRowsArray(newArray)
          setUpdatedResultArray(newArray)
          break;
        default:
          dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: result.data.message })));

        // setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
    })
  }
  const saveEditResult = () => {
    const valuesArray = updatedResultArray.map(item => item.value);

    const dbObject = {};
    for (let i = 0; i < output.DB_column_name.length; i++) {
      dbObject[output.DB_column_name[i]] = valuesArray[i];
    }
    // console.log('11dbObject', dbObject)

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.proteinAdd,
      data: {
        "add_info": dbObject
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: result.data.message})));
          // setInfo(snackInfo.open(snackType.success, result.data.message))
          break;
        default:
          dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: result.data.message})));
          // setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      setIsLoading(false)
      setResultError(numResultError + 1)
    })
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    navigate(paths.site.proteinPeptideCls)
    closeDialog();
  };

  const handleValueChange = (index, newValue) => {
    let updatedArray = [...updatedResultArray];
    updatedArray[index].value = removeTag(newValue)
    if (index === 0) {
      updatedArray[0].value = newValue.toUpperCase()
    }
    if (index === 2) {
      if (/\d/.test(newValue) || newValue === '') {
        updatedArray[2].value = originSequence
        setIsSequenceValid(false)
      } else {
        setIsSequenceValid(true)
        updatedArray[4].value = sequenceLength(newValue)
        updatedArray[2].value = newValue.toUpperCase()
      }
    }
    if (index === 9) {
      updatedArray[9].value = newValue
    }
    if (index === 10) {
      updatedArray[10].value = newValue
    }
    // console.log("10updatedArray", updatedArray)
  };
  const removeTag = (hasTagValue) => {
    const withoutStartP = hasTagValue.replace(/^<p>/, '');
    const noTag = withoutStartP.replace(/<\/p>$/, '');
    return noTag
  };
  const sequenceLength = (sequence) => {
    const length = sequence.length
    setLength(length)
    return length
  };
  const getClassification = (classId) => {
    let classify= "";
    classCode.map((item, index) => (
      item.code === parseInt(classId) ? classify = item.name + " protein" : null
    ))
    setClassification(classify)
    return classify
  }
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (isResultCode200 === false && isTimeout === false && numResultError < 5)
      eventResult();
    getCodeP();

  }, [output, rowsArray, updatedResultArray,])
  useEffect(() => {
    getCodeC(codeP);
  }, [codeP]);
  // -----render-------------------------------------------------------
  return (
    <div className={`${ppsScss["main"]} ${listLayoutScss["main"]}`}>
      <div className={`${ppsScss["page-title-block"]}  ${listLayoutScss["page-title-block"]}`}>
        <IconButton onClick={() => { navigate(-1) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
        {texts.title}
      </div>
      <MuiTableContainer size='small' >
        <TableBody >
          {isLoading ? <LoadingAnime /> :
            <TableCell>
              {rowsArray.map((item, index) => (
                <TableRow key={index} className={_scss["table-raw"]} >
                  <MuiTableHeadCell1st>{item.index} {item.name}</MuiTableHeadCell1st>
                  <Div style={{ margin: "10px" }}>
                    {item.name === "SPP_ID" || item.name === "Type" || item.name === "Term" || item.name === "Source"|| item.name === "Classification"
                      ? (<TextField
                        multiline
                        variant="outlined"
                        className={_scss['row-textfield']}
                        defaultValue={item.value}
                        onChange={(event) => handleValueChange(index, event.target.value)}
                        label='Required'
                        required
                      />) : (item.name === "Sequence") ? (
                        <TextField
                          multiline
                          variant="outlined"
                          className={_scss['row-textfield']}
                          defaultValue={item.value}
                          onChange={(event) => handleValueChange(index, event.target.value)}
                          label='Required'
                          required
                          error={!isSequenceValid}
                          helperText="only letters(a-z) are allowed"
                        />)
                        : (item.name === "Length") ? (
                          <Div scssName={'item-text'}>{length}</Div>
                        ) : (item.name === "Classification") ? (
                          <Div sscssName={'item-text'}>{classification}</Div>)
                          : (item.name === "Code C") ? (
                            <MuiSelect id="standard-select-class"
                              scssName={'select-class'}
                              value={codeC}
                              onChange={saveCodeC}
                            >
                              {proteinCode.map((option, index) => (
                                <MenuItem key={index} value={option.code}>{option.code} - {option.name}</MenuItem>
                              ))}
                            </MuiSelect>
                          )
                            : (item.name === "Code P") ? (
                              <MuiSelect id="standard-select-class"
                                scssName={'select-class'}
                                value={codeP}
                                onChange={saveCodeP}
                              >
                                {classCode.map((option, index) => (
                                  <MenuItem key={index} value={option.code}>{option.code} - {option.name}</MenuItem>
                                ))}
                              </MuiSelect>)
                              : (
                                <Div className={_scss["table-raw"]} >
                                  <CKEditor
                                    editor={ClassicEditor}
                                    onReady={editor => {
                                      // editor.ui.view.editable.element.style.height = '200px';
                                    }}
                                    config={{
                                      removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                    }}
                                    data={item.value}
                                    onChange={(event, editor) => {
                                      const newData = editor.getData();
                                      handleValueChange(index, newData);
                                    }}
                                    style={{ width: '100%', margin: '10px' }}
                                  />
                                </Div>
                              )}
                  </Div>
                </TableRow>))
              }
            </TableCell>
          }
        </TableBody>
      </MuiTableContainer>
      {/* button */}
      <Box scssName={'button'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" color="grey" onClick={openDialog}>
          Cancel
        </Button>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: '#4d7dae' }}
          onClick={saveEditResult}>
          Save
        </Button>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have unsaved changes. Do you want to save them?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: '#FF2D2DB2' }}
              onClick={() => {
                navigate(paths.site.proteinPeptideCls)
              }} >Leave
            </Button>
            <Button onClick={() => {
              handleConfirm();
              saveEditResult();
            }}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} /> */}
    </div>
  )
}
export default ProteinAdd