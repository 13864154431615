import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
  },
  /* [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }, */
}));
export default StyledTableCell