import 'assets/scss/common.scss';
import Topbar from "components/Topbar";
import TopbarCRUD from "components/TopbarCRUD";

export function LayoutWithTopBar({ children }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "hidden" }}>
            <Topbar />

            <div style={{ marginTop: "70px", padding: "30px 45px", overflowY: "auto"}}> 
                {children}
            </div>
        </div>
    )
}

export function LayoutWithTopBarCRUD({ children }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "hidden" }}>
            <TopbarCRUD />
            <div style={{ marginTop: "70px", padding: "30px 45px", display: 'flex', justifyContent: 'center', overflowY: "auto" }}>
                {children}
            </div>
        </div>
    )
}