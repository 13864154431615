export const dialogMaxWidth = { style: { maxWidth: "500px" } };

export const handleKeyDown = ({ event, enterAction }) => {
  if (event.keyCode === 13) {
    enterAction();
  }
};

export const checkOnlyCharAndNumber = (keyword) => {
  const regex = /^[A-Za-z0-9]+$/;
  return regex.test(keyword) || keyword==="";
};

export const invalidChar = "Invalid character";
