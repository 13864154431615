import { createSlice } from "@reduxjs/toolkit";
import { snackType } from "components/SnackBar";

const initSnackbarInfo = {
  isOpen: false,
  type: snackType.info,
  msg: "",
};

const initialState = {
  snackbarInfo: initSnackbarInfo,
  updateHomeBlock: true,
  totalOfPeptideCls: 0,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setSnackbarInfo: (state, action) => {
      state.snackbarInfo.isOpen = action.payload.isOpen;
      state.snackbarInfo.type = action.payload.type;
      state.snackbarInfo.msg = action.payload.msg;
    },
    closeSnackbar: (state, action) => {
      state.snackbarInfo.isOpen = false;
    },
    updateHomeBlockMode: (state, action) => {
      state.updateHomeBlock = !state.updateHomeBlock
    },
    updateTotalOfPeptideCls: (state, action) => {
      state.totalOfPeptideCls = action.payload.value
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSnackbarInfo, closeSnackbar, updateHomeBlockMode, updateTotalOfPeptideCls } =
  mainSlice.actions;

export default mainSlice.reducer;
