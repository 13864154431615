import { CircularProgress } from '@mui/material';

import "assets/scss/general.scss";

const LoadingAnime = ({size=30, ...props}) => (
  <div className="loading-block">
    <CircularProgress className="loading-circle" size={size} />
  </div>
)

export default LoadingAnime