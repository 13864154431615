
// -----official tools & third tools-------------------------------------------------------
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, IconButton, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import 'assets/scss/common.scss';
import _scss from "assets/scss/components/Peptide/PeptideIntro.module.scss";
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';

import { TagText } from 'components/MuiDiv';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTableCell from 'styledComponents/StyledTableCell';
import cookie from "utils/cookie";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig } from "utils/network/axios";
import { ROLE } from 'utils/role';
import paths from 'utils/webPath';

//---------------------------------------------------------------------------
const texts = {
  title: 'Introduction of',
}

const Div = ({ scssName, ...props }) => (
  <div {...props} className={props.className ? props.className : _scss[scssName]}>
    {props.children}
  </div>
)

const MuiTableContainer = (props) => (
  <TableContainer component={Paper} variant="outlined" className={"table-container"}>
    <Table {...props} aria-label="a dense table">
      {props.children}
    </Table>
  </TableContainer>
)

const MuiTableCell = (props) => (
  <StyledTableCell {...props} style={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
    {props.children}
  </StyledTableCell>
)

// const MuiTableHeadCell = (props) => (
//   <MuiTableCell className="bgc-topbar-blue color-white"
//     {...props}>
//     {props.children}
//   </MuiTableCell>
// )

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell {...props} align='center' component="th" scope="row" style={{ fontSize: 20 }} className={`bgc-grey50 ${_scss["TableHeadCell"]}`}>
    {props.children}
  </StyledTableCell>
)

const rowChildren = (row) => {
  return (
    <TableRow key={row.name}>
      <MuiTableHeadCell1st>{row.name}</MuiTableHeadCell1st>
      <MuiTableCell> <TagText html={row.value} /></MuiTableCell>
    </TableRow>
  )
}

//---------------------------------------------------------------------------
const PeptideIntroView = () => {
  // -----variables-------------------------------------------------------
  const classId = parseInt(cookie.getCookie(cookie.keys.classId))
  const [output, setOutput] = useState({}) //all of output
  const [rows, setRows] = useState([])
  const [classification, setClassification] = useState("")


  const [info, setInfo] = useState(snackInfo.init())
  const [isLoading, setIsLoading] = useState(true)
  const [numResultError, setResultError] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isResultCode200, setResultCode200] = useState(false)
  const navigate = useNavigate()

  const [roleId, setRoleId] = useState(null)

  // -----functions-------------------------------------------------------
  const apiResult = () => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.readPeptideIntro,
      data: {
        "ind": classId,
      }
    })).then(result => {
      //console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          let output = result.data
          setOutput(output)

          const newList = [
            { name: 'Function', value: output.display_name || "N.D" },
            { name: 'Introduction', value: output.introduction || "N.D" },
            { name: 'Primary literature', value: output.literature || "N.D" },
            { name: 'Additional Information', value: output.additional_informations || "N.D" },
          ]
          // console.log(output.display_name)
          setRows(newList)
          setClassification(output.display_name)
          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }
    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
    })
  }
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (isResultCode200 === false && isTimeout === false && numResultError < 5)
      apiResult();

    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    setRoleId(role_id)
  }, [])

  // -----render-------------------------------------------------------
  return (
    <Div scssName="layout">
      <Div scssName="page-title-block">
        <Div>
          <IconButton onClick={() => { navigate(paths.site.proteinPeptideSettings) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
          {texts.title} {classification}
        </Div>

        {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) &&
          <Button
            variant="contained"
            sx={{ bgcolor: '#4d7dae' }}
            onClick={() => {
              navigate(paths.site.peptideIntroEdit)
            }}>Edit</Button>}
      </Div>

      <MuiTableContainer size='small'>
        <TableBody>
          {rows.map((row, index) => (
            rowChildren(row, index)
          ))}
        </TableBody>
      </MuiTableContainer>

      <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />
    </Div>
  )
};
export default PeptideIntroView;