// -----official tools & third tools-------------------------------------------------------
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// -----custom tools-------------------------------------------------------
import pceadScss from "assets/scss/components/Protein/ProteinClsEditAddDialog.module.scss";
import SnackBar, { snackBarObj, snackInfo, snackType } from 'components/SnackBar';
import { useNavigate } from 'react-router-dom';
import cookie from 'utils/cookie';
import { dialogMaxWidth } from "utils/generalVar";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, handleErrMsg } from "utils/network/axios";


//---------------------------------------------------------------------------
const SelectPeptideClsDialog = (props) => {
  // -----variables-------------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [peptideClsList, setPeptideClsList] = useState([]);
  const [info, setInfo] = useState(snackInfo.init())

  const [selectedCls, setSelectedCls] = useState("")
  const dispatch = useDispatch();

  const navigate = useNavigate()

  // -----functions-------------------------------------------------------
  const getAllPeptide = () => {
    _axios(apiGetConfig({
      endpoint: apiEndPoint.site.getAllPeptide
    }))
      .then(result => {
        setIsLoading(false);

        switch (result.data.result_code) {
          case 200:

            let peptidesList = result.data["peptides"]
            peptidesList.sort(function (a, b) {
              return a["display_name"].localeCompare(b["display_name"]);
            });

            setPeptideClsList(peptidesList);

            if (peptidesList.length !== 0) {
              setSelectedCls(peptidesList[0].cls_id)
              cookie.setCookie(cookie.keys.selectedFilterPeptideClsId, peptidesList[0].cls_id)
            }
            break;
          default:
            setInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoading(false);
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  useEffect(() => {
    getAllPeptide();
    return () => {
    };
  }, [props.isOpen]);


  const cancelAction = () => {
    props.handleCancelAction()
  }

  // -----render-------------------------------------------------------
  return (
    <Dialog
      open={props.isOpen}
      onClose={cancelAction}
      aria-labelledby="select-peptide-cls-dialog"
      aria-describedby=""
      PaperProps={dialogMaxWidth}
    >
      <DialogTitle id="protein-edit-add-dialog-title">
        Select Peptide Classification
      </DialogTitle>
      <DialogContent className={pceadScss["content"]}>

        <FormControl fullWidth>
          <InputLabel id="cls-dropdown-label">Classification</InputLabel>
          <Select
            labelId="cls-dropdown-label"
            value={selectedCls}
            label="Classification"
            onChange={(event) => {
              setSelectedCls(event.target.value)
              cookie.setCookie(cookie.keys.selectedFilterPeptideClsId, event.target.value)
            }}
          >
            {peptideClsList.map((cls) => (<MenuItem value={cls.cls_id} key={cls.cls_id}>
              {cls.display_name}
            </MenuItem>))}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction} className={pceadScss["cancel-btn"]} >Cancel</Button>
        <Button
          disabled={isLoading || selectedCls === ""}
          onClick={() => {
            navigate(props.path)
          }}
          className={pceadScss["confirm-btn"]}>
          Continue
        </Button>
      </DialogActions>

      <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />

    </Dialog >)
};

export default SelectPeptideClsDialog;