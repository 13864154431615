import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, AppBar, Box, Button, Grid, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

// -----custom tools-------------------------------------------------------
import topbarScss from "assets/scss/components/Topbar.module.scss";
import { updateHomeBlockMode } from "store/mainSlice";
import cookie from 'utils/cookie';
import paths from 'utils/webPath';
import { listSelectType } from 'views/HomePage';

const pages = ['Protein', 'Peptide'];
export const PROTEIN = 0, PEPTIDE = 1, SETTINGS = 2, ACCOUNT_MANAGEMENT = 3;
const settings = ['Logout'];
const ACCOUNT_MANAGEMENT_ACTION = 1, LOGOUT = 0;

const systemNameIcon = (sxStyle) => {
  return (
    <Typography className={topbarScss["system-name"]} noWrap
      component="a"
      href={paths.site.home}
      sx={sxStyle}>SPP Admin</Typography>
  )
}

//---------------------------------------------------------------------------
const TopbarCRUD = () => {
  // -----variables-------------------------------------------------------
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const username = cookie.getCookie(cookie.keys.username)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  // -----functions-------------------------------------------------------
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = clickAction => {
    setAnchorElNav(null);
    switch (clickAction) {
      case PROTEIN:
        cookie.setCookie(cookie.keys.listSelectType, listSelectType.protein)
        dispatch(updateHomeBlockMode())
        navigate(paths.site.proteinPeptideCls)
        break;
      case PEPTIDE:
        cookie.setCookie(cookie.keys.listSelectType, listSelectType.peptide)
        dispatch(updateHomeBlockMode())
        navigate(paths.site.proteinPeptideCls)
        break;
      // case SETTINGS:
      //   navigate(paths.site.proteinPeptideSettings)
      //   break;
      default:
        cookie.setCookie(cookie.keys.homeSelectType, listSelectType.protein)
        break;
    }
  };


  const logoutEvent = () => {
    cookie.removeAllCookie()
    navigate({ to: paths.auth.login })
  }

  const handleCloseUserMenu = (clickAction) => {
    setAnchorElUser(null);

    switch (clickAction) {
      case LOGOUT:
        logoutEvent()
        break;

      case ACCOUNT_MANAGEMENT_ACTION:
        navigate(paths.site.accountManagement)
        break;

      default:
        break;
    }
  };

  // -----render-------------------------------------------------------
  return (
    <AppBar position="fixed" className={topbarScss["main"]}>
      {/* <Container maxWidth="xl"> */}
      <Toolbar disableGutters className={topbarScss["topbar"]} >
        {systemNameIcon({ display: { xs: 'none', md: 'flex' }, color: 'inherit' })}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            disableRipple
          >
            <MenuIcon />
          </IconButton>


          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page, index) => (
              <MenuItem key={page} onClick={() => { handleCloseNavMenu(index) }}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {systemNameIcon({ display: { xs: 'flex', md: 'none' }, flexGrow: 1, color: 'inherit', })}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page, index) => (
            <Button
              key={page}
              onClick={() => {
                openDialog();
                setIndex(index)
              }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}

          <Dialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">Confirm Save</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have unsaved changes. Do you want to save them?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { handleCloseNavMenu(index) }} sx={{ color: '#ff2d2db2', marginLeft: 'auto' }}>Leave</Button>
              <Button onClick={closeDialog}>Save</Button>
            </DialogActions>
          </Dialog>


        </Box>
        <Box sx={{ flexGrow: 0 }} className={topbarScss["menu-icon-block"]}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className={topbarScss["username-block"]} disableRipple>
            <PersonIcon sx={{ color: 'white', mr: 1 }} /> <Grid sx={{ display: { xs: 'none', md: 'flex', color: 'white' } }}>{username}</Grid>
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MenuItem key={setting} onClick={() => handleCloseUserMenu(index)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};

export default TopbarCRUD;
