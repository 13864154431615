export const NumberPerPage = 30;

const apiParams = {
  protein: {
    search({
      classification,
      source,
      filterSelection,
      filterValue,
      keyWord,
      lineSize=30,
      currentPage,
    }) {
      return {
        classification,
        source,
        filterSelection,
        filterValue,
        keyWord,
        lineSize,
        currentPage,
      };
    },
  },

  peptide: {
    search({
      cls_id,
      source,
      filterSelection,
      filterValue,
      keyWord,
      lineSize=30,
      currentPage,
    }) {
      return {
        cls_id,
        source,
        filterSelection,
        filterValue,
        keyWord,
        lineSize,
        currentPage,
      };
    },
  },
};

export default apiParams;
