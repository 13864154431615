import React from 'react';
import { TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import v from 'assets/scss/_variables.scss'

export const TableHeadRow = (props) => (
  <TableHead {...props}>
    <TableRow>
      {props.children}
    </TableRow>
  </TableHead>
)

export const TableRow2Body = (props) => (
  <TableRow {...props}
    sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
    {props.children}
  </TableRow>
)

export const MuiTableCell = ({ ...props }) => {
  let sxs = {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      whiteSpace: 'pre-line',

      height: '64px',
      boxSizing: 'border-box',

      color: v.white,
    },
    [`&.${tableCellClasses.body}`]: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',

      height: '60px',
      boxSizing: 'border-box',
      maxWidth: '300px',

      color: v.$darkGreen,
    },
  }
  if (props.sx !== undefined)
    Object.assign(sxs, props.sx)
  return (
    <TableCell {...props} sx={sxs}>
      {props.children}
    </TableCell>
  )
}
