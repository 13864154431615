// -----official tools & third tools-------------------------------------------------------
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import PropTypes from 'prop-types';

// -----custom tools-------------------------------------------------------
import pceadScss from "assets/scss/components/Protein/ProteinClsEditAddDialog.module.scss";

//---------------------------------------------------------------------------
const DoubleConfirmDialog = (props) => {
  // -----variables-------------------------------------------------------

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------
  return (<Dialog
    open={props.isOpen}
    onClose={props.handleClose}
    aria-labelledby="confirm-confirm-dialog"
    aria-describedby=""
  >
    <DialogTitle id="confirm-confirm-dialog-title">
      {props.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="confirm-confirm-description">
        {props.content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} className={pceadScss["cancel-btn"]}>Cancel</Button>
      <Button onClick={props.handleConfirmAction} className={pceadScss["confirm-btn"]}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>)
};

DoubleConfirmDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmAction: PropTypes.func.isRequired,
};
export default DoubleConfirmDialog;