// -----official tools & third tools-------------------------------------------------------

// -----custom tools-------------------------------------------------------
import pgbScss from "assets/scss/components/PaginationBlock.module.scss";


//---------------------------------------------------------------------------
const PaginationBlock = (props) => {
    // -----variables-------------------------------------------------------

    // -----functions-------------------------------------------------------

    // -----render-------------------------------------------------------
    return (<div className={pgbScss['pagination-block']}>
        {props.pages.map((item, index) => {
            let style = {}
            switch (item) {
                case String(props.pageNo.now):
                    style = { background: pgbScss.selectBg }
                    break;
                case String(String(props.words.page[1])):
                    // ...
                    style = { cursor: 'default', border: 0 }
                    break;
                default:
                    style = { background: pgbScss.unselectBg }
                    break;
            }
            return (
                <div className={pgbScss['bar-page']}
                    style={style}
                    key={index}
                    value={item}
                    onClick={(event) => { props.handleClickPage(item) }}>
                    <div className='b2strong-darkGreen'>{item}</div>
                </div>
            )
        })}

        {props.pages.length > 1 &&
            <>
                <div className={pgbScss['divider']} />

                <div className={pgbScss["go-to-page-block"]} title="Input page number">
                    <input
                        className={pgbScss["go-to-page-input"]}
                        id="go-to-page-input"
                        type="number"
                        placeholder="#"
                        onChange={(event) => { props.onInputChangeInGoTo(event) }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                props.handleClickPage(event.target.value)
                            }
                        }}
                        value={props.goToValue} />
                </div>

                <div className={pgbScss["go-to-btn"]}
                    onClick={() => { props.handleClickPage(props.goToValue) }}>
                    Go to
                </div>
            </>}
    </div>)
};

export default PaginationBlock;