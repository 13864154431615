// -----official tools & third tools-------------------------------------------------------
import { useEffect, useState } from 'react';

// -----custom tools-------------------------------------------------------
import "assets/scss/views/ProteinPeptideCls.module.scss";
import ProteinClsBlock from 'components/Protein/ProteinClsBlock';
import { useSelector } from 'react-redux';
import cookie from 'utils/cookie';
import TextEditor from 'components/TextEditor';
import ProteinAdd from './Protein/ProteinEdit';
import ProteinList from './Protein/ProteinList';
import PeptideList from './Peptide/PeptideList';
import { listSelectType } from './HomePage';

//---------------------------------------------------------------------------
const ProteinPeptideCls = () => {
  // -----variables-------------------------------------------------------
  const [selectType, setSelectType] = useState(cookie.getCookie(cookie.keys.listSelectType) ?? listSelectType.protein);


  const isUpdateSelectType = useSelector(state => state.main.updateHomeBlock)

  // -----functions-------------------------------------------------------
  useEffect(() => {
    const tmpListSelectType = cookie.getCookie(cookie.keys.listSelectType);
    setSelectType(tmpListSelectType)

    if (!Object.values(listSelectType).includes(tmpListSelectType)) {
      setSelectType(listSelectType.protein)
    }
    return () => {
    };
  }, [isUpdateSelectType]);

  // -----render-------------------------------------------------------
  return (
    <div className='home'>
      {selectType === listSelectType.protein ? <ProteinList /> : <PeptideList />}
    </div>);
};

export default ProteinPeptideCls;
