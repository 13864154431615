import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


// -----custom tools-------------------------------------------------------
import topbarScss from "assets/scss/components/Topbar.module.scss";
import { updateHomeBlockMode } from "store/mainSlice";
import cookie from 'utils/cookie';
import { ROLE } from 'utils/role';
import paths from 'utils/webPath';
import { listSelectType } from 'views/HomePage';

export const PROTEIN = 0, PEPTIDE = 1, ACCOUNT_MANAGEMENT = 2;
const settings = ['Logout'];
const ACCOUNT_MANAGEMENT_ACTION = 1, LOGOUT = 0;

const systemNameIcon = (sxStyle) => {
  return (
    <Typography className={topbarScss["system-name"]} noWrap
      component="a"
      href={paths.site.home}
      sx={sxStyle}>SPP Admin</Typography>
  )
}

//---------------------------------------------------------------------------
const Topbar = () => {
  // -----variables-------------------------------------------------------
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [pages, setPages] = React.useState(['Protein', 'Peptide']);

  const username = cookie.getCookie(cookie.keys.username)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // -----functions-------------------------------------------------------
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = clickAction => {
    setAnchorElNav(null);

    switch (clickAction) {
      case PROTEIN:
        cookie.setCookie(cookie.keys.selectedFilterProteinSourceId, "")
        cookie.setCookie(cookie.keys.listSelectType, listSelectType.protein)
        dispatch(updateHomeBlockMode())
        navigate(paths.site.proteinPeptideCls)
        break;
      case PEPTIDE:
        cookie.setCookie(cookie.keys.listSelectType, listSelectType.peptide)
        dispatch(updateHomeBlockMode())
        navigate(paths.site.proteinPeptideCls)
        break;
      case ACCOUNT_MANAGEMENT:
        navigate(paths.site.accountManagement)
        break;
      default:
        cookie.setCookie(cookie.keys.listSelectType, listSelectType.protein)
        break;
    }
  };


  const logoutEvent = () => {
    cookie.removeAllCookie()
    navigate({ to: paths.auth.login })
  }

  const handleCloseUserMenu = (clickAction) => {
    setAnchorElUser(null);

    switch (clickAction) {
      case LOGOUT:
        logoutEvent()
        break;
      case ACCOUNT_MANAGEMENT_ACTION:
        navigate(paths.site.accountManagement)
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {

    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    if (role_id === ROLE.ROOT) {
      setPages([...pages, 'Account'])
    }
  }, [])

  // -----render-------------------------------------------------------
  return (
    <AppBar position="fixed" className={topbarScss["main"]}>
      {/* <Container maxWidth="xl"> */}
      <Toolbar disableGutters className={topbarScss["topbar"]} >
        {systemNameIcon({ display: { xs: 'none', md: 'flex' }, color: 'inherit' })}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            disableRipple
          >
            <MenuIcon />
          </IconButton>


          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page, index) => (
              <MenuItem key={page} onClick={() => { handleCloseNavMenu(index) }}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {systemNameIcon({ display: { xs: 'flex', md: 'none' }, flexGrow: 1, color: 'inherit', })}


        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page, index) => (
            <Button
              key={page}
              onClick={() => { handleCloseNavMenu(index) }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0 }} className={topbarScss["menu-icon-block"]}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className={topbarScss["username-block"]} disableRipple>
            <PersonIcon sx={{ color: 'white', mr: 1 }} /> <Grid sx={{ display: { xs: 'none', md: 'flex', color: 'white' } }}>{username}</Grid>
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MenuItem key={setting} onClick={() => handleCloseUserMenu(index)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};

export default Topbar;
