// -----official tools & third tools-------------------------------------------------------
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, IconButton } from '@mui/material';
import ProteinClsBlock from 'components/Protein/ProteinClsBlock';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'utils/cookie';


// -----custom tools-------------------------------------------------------
import v from "assets/scss/_variables.scss";
import 'assets/scss/common.scss';
import listLayoutScss from "assets/scss/layout/ListLayout.module.scss";
import ppsScss from "assets/scss/views/ProteinPeptideSettings.module.scss";

import PeptideClsBlock from 'components/Peptide/PeptideClsBlock';
import { useNavigate } from 'react-router-dom';
import paths from 'utils/webPath';
import { ROLE } from 'utils/role';
import PeptideClsEditAddDialog from 'components/Peptide/PeptideClsEditAddDialog';
//---------------------------------------------------------------------------
const settingType = ["protein", "peptide"]
const ProteinPeptideSettings = () => {
	// -----variables-------------------------------------------------------
	const [selectedType, setSelectedType] = useState("");
	const navigate = useNavigate();

	const totalOfPeptideCls = useSelector(state => state.main.totalOfPeptideCls)
	const [roleId, setRoleId] = useState(null)

	const [isOpenPeptideAddClsDialog, setIsOpenPeptideAddClsDialog] = useState(false);
	const [isAddPeptideMode, setIsAddPeptideMode] = useState(true);

	const [clsList, setClsList] = useState([]);
	// -----functions-------------------------------------------------------

	const getSelectedSettingsType = () => {
		const selectedSettingType = cookie.getCookie(cookie.keys.selectedSettingType)
		if (settingType.filter(item => item === selectedSettingType).length === 0) {
			setSelectedType(settingType[0])
			cookie.setCookie(cookie.keys.selectedSettingType, settingType[0])
		} else {
			setSelectedType(selectedSettingType)
		}
	}

	useEffect(() => {
		getSelectedSettingsType()
		const role_id = Number(cookie.getCookie(cookie.keys.roleId));
		setRoleId(role_id)

		return () => {
		};
	}, []);
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (isOpenPeptideAddClsDialog) {
				event.preventDefault();
				event.returnValue = ''; // empty → browser default message
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isOpenPeptideAddClsDialog]);

	// -----render-------------------------------------------------------
	return (
		<div className={`${ppsScss["main"]} ${listLayoutScss["main"]}`}>
			<div className={`${ppsScss["page-title-block"]}  ${listLayoutScss["page-title-block2"]}`}>
				<div>
					<IconButton onClick={() => { navigate(paths.site.home) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
					{selectedType === settingType[0] ? "Protein Classification & Source" : `Peptide Classification (${totalOfPeptideCls})`}
				</div>
				{selectedType === settingType[1] && (roleId === ROLE.ROOT || roleId === ROLE.MANAGER) &&
					<Button
						variant="contained"
						// disabled={disableBtn}
						sx={{ marginLeft: 2, bgcolor: '#4d7dae' }}
						onClick={() => {
							navigate(paths.site.peptideClsAdd)
							// setIsAddPeptideMode(true)
							// setIsOpenPeptideAddClsDialog(true)
						}}>New class</Button>}
				{/* <PeptideClsEditAddDialog
					title={"Add Peptide Classification "}
					isOpen={isOpenPeptideAddClsDialog}
					handleCancelAction={() => {
						setIsOpenPeptideAddClsDialog(false)
					}}
					handleSaveAction={(newPeptideClsDisplayName, newPeptideClsTableName) => {
						if (isAddPeptideMode) {
							setClsList([...clsList, {
								"display_name": newPeptideClsDisplayName,
								"table_name": newPeptideClsTableName
							}])
						}
						setIsOpenPeptideAddClsDialog(false)
					}}
				isAddMode={isAddPeptideMode}
				/> */}
			</div>

			{/* <div className={ppsScss["tabs"]}>
            <div className={`${ppsScss["protein-tab"]} ${selectedType === settingType[0] ? ppsScss["is-select"] : ""}`} onClick={() => {
                setSelectedType(settingType[0])
                cookie.setCookie(cookie.keys.selectedSettingType, settingType[0])
            }}>
                Protein
            </div>

            <div className={`${ppsScss["peptide-tab"]} ${selectedType === settingType[1] ? ppsScss["is-select"] : ""}`} onClick={() => {
                setSelectedType(settingType[1])
                cookie.setCookie(cookie.keys.selectedSettingType, settingType[1])
            }}>
                Peptide
            </div>
        </div> */}

			{selectedType === settingType[0] ?
				<ProteinClsBlock /> :
				<PeptideClsBlock />
			}
		</div>)
};

export default ProteinPeptideSettings;