// -----official tools & third tools-------------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v from "assets/scss/_variables.scss";
import pcbScss from "assets/scss/components/Protein/ProteinClsBlock.module.scss";
import LoadingAnime from 'components/LoadingAnime';
import { snackBarObj, snackType } from 'components/SnackBar';
import { setSnackbarInfo } from "store/mainSlice";
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import { ROLE } from 'utils/role';
import ProteinClsEditAddDialog from './ProteinClsEditAddDialog';
import ProteinSourceEditAddDialog from './ProteinSourceEditAddDialog';

const CLS = 1, SOURCE = 2;

//---------------------------------------------------------------------------
const ProteinClsBlock = () => {
  // -----variables-------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  // const [clsList, setClsList] = useState({});
  const [clsSourceObj, setClsSourceObj] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);

  // const [selectedCls, setSelectedCls] = useState("");
  const [selectedSource, setSelectedSource] = useState("");

  const [selectedSourceId, setSelectedSourceId] = useState(null);

  
  const [selectedClsCountId, setSelectedClsCountId] = useState(null);
  const [selectedSourceCountId, setSelectedSourceCountId] = useState(null);
  

  const [isOpenProteinDelConfirmDialog, setIsOpenProteinDelConfirmDialog] = useState(false);
  const [isOpenProteinDelSourceConfirmDialog, setIsOpenProteinDelSourceConfirmDialog] = useState(false);

  const [isOpenProteinAddClsDialog, setIsOpenProteinAddClsDialog] = useState(false);
  const [isOpenProteinAddSourceDialog, setIsOpenProteinAddSourceDialog] = useState(false);

  const [isAddProteinMode, setIsAddProteinMode] = useState(true);
  const [isAddProteinSourceMode, setIsAddProteinSourceMode] = useState(true);

  const [selectedClsList, setSelectedClsList] = useState([]);
  const [selectedSourceList, setSelectedSourceList,] = useState([]);

  const [selectedClsId, setSelectedClsId,] = useState(null);
  const [selectedClsName, setSelectedClsName,] = useState("");

  const [roleId, setRoleId] = useState(null)


  const [clsHeadCells, setClsHeadCells] = useState([
    {
      id: 'count_id',
      numeric: false,
      disablePadding: false,
      isCenter: true,
      label: 'ID',
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      isCenter: false,
      label: 'Code_P',
    },
    {
      id: 'code_c',
      numeric: false,
      disablePadding: false,
      isCenter: false,
      label: 'Code_C',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      isCenter: false,
      label: 'Name',
    },

  ])

  const [sourceHeadCells, setSourceHeadCells] = useState([
    {
      id: 'count_id',
      numeric: false,
      disablePadding: false,
      isCenter: true,
      label: 'ID',
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      isCenter: false,
      label: 'Code_C',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      isCenter: false,
      label: 'Name',
    },
  ])

  const [isLoadingDisableEnableCls, setIsLoadingDisableEnableCls] = useState(false);
  const [isLoadingDisableEnableSource, setIsLoadingDisableEnableSource] = useState(false);

  const [isShowDisableSourceBtn, setIsShowDisableSourceBtn] = useState(false);
  const [isShowEnableSourceBtn, setIsShowEnableSourceBtn] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // -----functions-------------------------------------------------------
  const getProteinClsList = () => {
    _axios(apiGetConfig({
      endpoint: apiEndPoint.site.getProteinClsList
    }))
      .then(result => {
        setIsLoading(false);
        setDisableBtn(false)
        switch (result.data.result_code) {
          case 200:
            setErrMsg("")
            handleClsSourceList(result.data["item(s)"])
            break;
          default:
          // setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoading(false);
        setDisableBtn(true)
        setErrMsg(handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error })))
      })
  }

  const handleClsSourceList = (rowData) => {

    let obj = {}
    rowData.forEach((row) => {
      const classification = row.classification;
      const source = row.source;

      if (!obj[classification]) {
        obj[classification] = {
          cls_status: row.class_status,
          cls_id: row.classification_code_p,
          cls_id_code_c: row.classification_code_c,
          cls_count_id: row.classification_count_id,
          source: {}
        };
      }

      if (source !== null) {
        obj[classification].source[source] = {
          source_status: row.source_status,
          source_id: row.source_id,
          source_count_id: row.source_count_id
        };
      }

    });


    // Sort Class by count id
    const sortedObj = Object.fromEntries(
      Object.entries(obj).sort(([, a], [, b]) => a.cls_count_id - b.cls_count_id)
    );

    // Sort Source by count id
    for (const key in sortedObj) {
      if (sortedObj[key].source) {
        sortedObj[key].source = Object.fromEntries(
          Object.entries(sortedObj[key].source).sort(
            ([, a], [, b]) => a.source_count_id - b.source_count_id
          )
        );
      }
    }
    setClsSourceObj(sortedObj)
  }


  useEffect(() => {
    getProteinClsList()

    const role_id = Number(cookie.getCookie(cookie.keys.roleId));
    setRoleId(role_id)


    if (role_id === ROLE.ROOT || role_id === ROLE.MANAGER) {
      setClsHeadCells([...clsHeadCells,
      {
        id: 'show',
        numeric: false,
        disablePadding: false,
        isCenter: true,
        label: 'Show',
      },
      {
        id: 'edit',
        numeric: false,
        disablePadding: false,
        isCenter: true,
        label: 'Edit',
      }])

      setSourceHeadCells([...sourceHeadCells,
      {
        id: 'show',
        numeric: false,
        disablePadding: false,
        isCenter: true,
        label: 'Show',
      },
      {
        id: 'edit',
        numeric: false,
        disablePadding: false,
        isCenter: true,
        label: 'Edit',
      }])
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isOpenProteinAddClsDialog || isOpenProteinAddSourceDialog) {
        event.preventDefault();
        event.returnValue = ''; // empty → browser default message
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isOpenProteinAddClsDialog, isOpenProteinAddSourceDialog]);


  useEffect(() => {
    if (Object.entries(clsSourceObj).length !== 0 && selectedClsName === "") {
      let keysArray = Object.keys(clsSourceObj)
      let firstClsName = keysArray[0]
      setSelectedClsName(firstClsName)

      setSelectedClsId(clsSourceObj[firstClsName].cls_id)
    }
    return () => {
    };
  }, [clsSourceObj]);



  const handleClsRowClick = (event, name, id) => {
    setIsShowDisableSourceBtn(false)
    setIsShowEnableSourceBtn(false)
    setSelectedSourceList([])
    setSelectedClsId(id)
    setSelectedClsName(name)
  };

  const isSelectedSource = (name) => {
    const selectedIndex = selectedSourceList.findIndex(item => item.sourceName === name);
    return selectedIndex === -1 ? false : true;
  }

  const handleSourceRowClick = (event, sourceName, status, id) => {
    const selectedIndex = selectedSourceList.findIndex(item => item.sourceName === sourceName);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSourceList, [{ sourceName, status, id }]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSourceList.slice(1));
    } else if (selectedIndex === selectedSourceList.length - 1) {
      newSelected = newSelected.concat(selectedSourceList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSourceList.slice(0, selectedIndex),
        selectedSourceList.slice(selectedIndex + 1),
      );
    }

    showDisableBtnByMulSelect(newSelected)
    setSelectedSourceList(newSelected);

  };

  const handleSelectAllClsClick = (event) => {
    if (event.target.checked) {
      const newSelected = Object.entries(clsSourceObj).map((item) => item[0]);
      setSelectedClsList(newSelected)
      return;
    }

    setSelectedSourceList([]);
    setSelectedClsList([]);
  };

  const handleSelectAllSourceClick = (event) => {
    if (event.target.checked) {

      const sourceDataInCls = clsSourceObj[selectedClsName].source;
      const sourceArray = Object.entries(sourceDataInCls).map(([key, value]) => {
        return {
          sourceName: key,
          status: value.source_status,
          id: value.source_id
        };
      });
      setSelectedSourceList(Object.values(sourceArray));
      showDisableBtnByMulSelect(Object.values(sourceArray))
      return;
    }
    setSelectedSourceList([]);
    showDisableBtnByMulSelect([])
  };


  // -----components-------------------------------------------------------
  function ClsTableHead(props) {
    return (
      <TableHead>
        <TableRow>
          {clsHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? 'center' : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              <b>{headCell.label}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }



  function SourceTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } =
      props;

    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all classification',
              }}
            />
          </TableCell> */}
          {sourceHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? 'center' : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              <b>{headCell.label}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const showDisableBtnByMulSelect = (selectedList) => {
    let sameStatusValue;

    const statusSet = new Set(selectedList.map(item => item.status));
    const uniqueStatusValues = [...statusSet];

    if (selectedList.length === 0) {
      setIsShowDisableSourceBtn(false)
      setIsShowEnableSourceBtn(false)
      return
    }

    if (statusSet.size > 1) {
      // has the different value
      setIsShowDisableSourceBtn(false)
      setIsShowEnableSourceBtn(false)
    } else {
      sameStatusValue = uniqueStatusValues[0]
      if (sameStatusValue === 1 && selectedList.length > 1) {
        setIsShowDisableSourceBtn(true)
        setIsShowEnableSourceBtn(false)
      } else {
        if (selectedList.length > 1) {
          setIsShowDisableSourceBtn(false)
          setIsShowEnableSourceBtn(true)
        }
      }

    }
  }

  const disableEnableClsSource = (event, type, status, ids, names) => {
    let statusBoolean = status === 1 ? true : false


    if (ids.length > 1) {
      type === CLS && setIsLoadingDisableEnableCls(true)
      type === SOURCE && setIsLoadingDisableEnableSource(true)
    } else {
      event.stopPropagation();
    }

    let sendData = {
      "type": type,
      "ids": ids,
      "show": !statusBoolean
    }

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.updateProteinClsSourceStatus,
      data: sendData
    }))
      .then(result => {
        type === CLS && setIsLoadingDisableEnableCls(false)
        type === SOURCE && setIsLoadingDisableEnableSource(false)

        switch (result.data.result_code) {
          case 200:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: `${statusBoolean ? "Disable" : "Enable"} successfully` })));

            let tmpClsSourceObj = { ...clsSourceObj }

            names.forEach(name => {
              if (type === CLS) {
                tmpClsSourceObj[name].cls_status = statusBoolean ? 0 : 1;

                for (const rowKey in tmpClsSourceObj[name].source) {
                  tmpClsSourceObj[name].source[rowKey].source_status = statusBoolean ? 0 : 1;
                }
              }
              if (type === SOURCE) {
                tmpClsSourceObj[selectedClsName].source[name].source_status = statusBoolean ? 0 : 1;
              }
            })
            setClsSourceObj(tmpClsSourceObj)

            if (type === SOURCE && ids.length > 1) {
              setSelectedSourceList([])
              setIsShowDisableSourceBtn(false)
              setIsShowEnableSourceBtn(false)
            }
            break;
          default:
        }

      }).catch(err => {
        type === CLS && setIsLoadingDisableEnableCls(false)
        type === SOURCE && setIsLoadingDisableEnableSource(false)
        setErrMsg(handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error })))
      })
  }



  // -----render-------------------------------------------------------
  return (<div className={pcbScss["main"]}>
    {/* <div className={pcbScss["page-title-block"]}>
      Protein Settings
    </div> */}

    <div className={pcbScss["settings-block"]}>
      <div className={pcbScss["cls-block"]}>
        <div className={pcbScss["cls-title-block"]}>
          <span className={pcbScss["cls-title"]} style={{ fontSize: v.title2 }}>Protein Classification {
            Object.entries(clsSourceObj).length !== 0 ? `(${Object.entries(clsSourceObj).length})` : ""}

            {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <IconButton aria-label="add"
              size="small"
              disabled={disableBtn}
              sx={{ marginLeft: 2 }}
              onClick={() => {
                setIsAddProteinMode(true)
                setIsOpenProteinAddClsDialog(true)
              }}>
              <AddIcon fontSize="small" />
            </IconButton>} </span>
          <div className={pcbScss["btn-title"]}>
            <ProteinClsEditAddDialog
              title={isAddProteinMode ? 'Add Protein Classification' : `Edit Protein Classification`}
              isOpen={isOpenProteinAddClsDialog}
              handleCancelAction={() => {
                setIsOpenProteinAddClsDialog(false)
              }}
              handleSaveAction={(newProteinClsName, newProteinClsId, clsCodeC, clsCountId) => {
                let newJsonObject = {
                  ...clsSourceObj,
                };

                if (!isAddProteinMode) {
                  newJsonObject[newProteinClsName] = clsSourceObj[selectedClsName]
                  delete newJsonObject[selectedClsName];
                } else {
                  newJsonObject[newProteinClsName] = {
                    cls_id: newProteinClsId,
                    cls_status: 1,
                    cls_id_code_c: clsCodeC,
                    cls_count_id: clsCountId,
                    source: {}
                  }
                }

                setClsSourceObj(newJsonObject)
                setSelectedClsName(newProteinClsName)

                setIsOpenProteinAddClsDialog(false)
              }}
              isAddMode={isAddProteinMode}
              nameValue={selectedClsName}
              clsId={isAddProteinMode ? null : clsSourceObj[selectedClsName].cls_id}
              clsIdCode_c={isAddProteinMode ? null : clsSourceObj[selectedClsName].cls_id_code_c}
              clsCountId={Object.entries(clsSourceObj).length + 1}
              selectedClsCountId={selectedClsCountId}
            />
          </div>
        </div>

        {isLoading ? <LoadingAnime /> :
          <TableContainer className={pcbScss["fullHeightTableContainer"]}>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              stickyHeader
            >
              <ClsTableHead />
              <TableBody>
                {Object.entries(clsSourceObj).map(([key, value]) => {
                  const labelId = `table-checkbox-${key}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClsRowClick(event, key, value.cls_id)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={key}
                      selected={selectedClsId === value.cls_id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell align="center">{value.cls_count_id}</TableCell>
                      <TableCell align="left">{value.cls_id}</TableCell>
                      <TableCell align="left">{value.cls_id_code_c}</TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {key}
                      </TableCell>

                      {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <TableCell align="center">
                        <IconButton aria-label="disable-enable-protein-class" size="small" disabled={isLoadingDisableEnableSource || (roleId !== ROLE.ROOT && roleId !== ROLE.MANAGER)} onClick={(event) => {
                          disableEnableClsSource(event, CLS, value.cls_status, [value.cls_id], [key])
                        }}>
                          {value.cls_status === 1 ?
                            <ToggleOnIcon fontSize="medium" sx={{ color: v.success2 }} /> : <ToggleOffIcon fontSize="medium" sx={{ color: v.error }} />}
                        </IconButton>
                      </TableCell>
                      }

                      {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <TableCell align="center">
                        <IconButton aria-label="add" size="small" disabled={disableBtn}
                          title="Edit"
                          onClick={() => {
                            setIsAddProteinMode(false)
                            setIsOpenProteinAddClsDialog(true)
                            setSelectedClsCountId(value.cls_count_id)
                          }}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }

      </div>

      <div className={pcbScss["source-block"]}>
        <div className={pcbScss["source-title-block"]}>
          <span className={pcbScss["source-title"]} style={{ fontSize: v.title2 }}>Protein Source {selectedClsName !== "" && `(${selectedSourceList.length !== 0 ? `${selectedSourceList.length}/` : ""}${Object.entries(clsSourceObj[selectedClsName].source).length})`}

            <span className={pcbScss["btn-title"]}>
              {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <IconButton aria-label="add" size="small" disabled={disableBtn} onClick={() => {
                setIsAddProteinSourceMode(true)
                setIsOpenProteinAddSourceDialog(true)
              }}>
                <AddIcon fontSize="small" />
              </IconButton>}

              {/* {isShowDisableSourceBtn &&
                <IconButton aria-label="delete-protein-source" size="small"
                  disabled={disableBtn}
                  title="Disable"
                  onClick={(event) => {
                    // setIsOpenProteinDelSourceConfirmDialog(true)
                    disableEnableClsSource(event, SOURCE, 1, selectedSourceList.map(item => item.id), selectedSourceList.map(item => item.sourceName))
                    setIsOpenProteinDelSourceConfirmDialog(false)
                  }}>
                  <ToggleOffIcon fontSize="medium" sx={{ color: v.error }} />
                </IconButton>} */}


              {/* <DeleteConfirmDialog
              title="Disable Source"
              content={`Are you sure to disable ${selectedSourceList.join(", ")}?`}
              isOpen={isOpenProteinDelSourceConfirmDialog}
              handleClose={() => {
                setIsOpenProteinDelSourceConfirmDialog(false)
              }}
              handleConfirmAction={() => {
                disableEnableClsSource(SOURCE, 0, selectedSourceList.map(item => item.id), selectedSourceList.map(item => item.sourceName))
                setIsOpenProteinDelSourceConfirmDialog(false)
              }} /> */}


              {/* {isShowEnableSourceBtn &&
                <IconButton size="small"
                  disabled={disableBtn}
                  title="Enable"
                  onClick={(event) => {
                    // setIsOpenProteinDelSourceConfirmDialog(true)
                    disableEnableClsSource(event, SOURCE, 0, selectedSourceList.map(item => item.id), selectedSourceList.map(item => item.sourceName))
                    setIsOpenProteinDelSourceConfirmDialog(false)
                  }}>
                  <ToggleOnIcon fontSize="medium" sx={{ color: v.success2 }} />
                </IconButton>} */}
            </span>
          </span>
        </div>

        {isLoading ? <LoadingAnime /> :
          selectedClsName !== "" ?

            <TableContainer className={pcbScss["fullHeightTableContainer"]}>
              <Table
                aria-labelledby="sourceTableTitle"
                size="small"
                stickyHeader
              >
                <SourceTableHead
                  numSelected={selectedSourceList.length}
                  onSelectAllClick={handleSelectAllSourceClick}
                  rowCount={Object.entries(clsSourceObj[selectedClsName].source).length}
                />
                <TableBody >
                  {
                    Object.entries(clsSourceObj[selectedClsName].source).map(([key, value]) => {

                      const isItemSelected = isSelectedSource(key);
                      const labelId = `table-checkbox-${key}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleSourceRowClick(event, key, value.source_status, value.source_id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={key}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell align="center">{value.source_count_id}</TableCell>
                          <TableCell align="left">{value.source_id}</TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {key}
                          </TableCell>

                          {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <TableCell align="center">
                            <IconButton aria-label="disable-enable-protein-class" size="small" disabled={isLoadingDisableEnableCls || clsSourceObj[selectedClsName].cls_status === 0 || (roleId !== ROLE.ROOT && roleId !== ROLE.MANAGER)} onClick={(event) => {
                              disableEnableClsSource(event, SOURCE, value.source_status, [value.source_id], [key])
                            }}>
                              {value.source_status === 1 ?
                                <ToggleOnIcon fontSize="medium" sx={{ color: v.success2 }} /> : <ToggleOffIcon fontSize="medium" sx={{ color: clsSourceObj[selectedClsName].cls_status === 0 ? v.grey50 : v.error }} />}
                            </IconButton>
                          </TableCell>
                          }


                          {(roleId === ROLE.ROOT || roleId === ROLE.MANAGER) && <TableCell align="center">
                            <IconButton aria-label="add" size="small" disabled={disableBtn}
                              title="Edit"
                              onClick={() => {
                                setIsAddProteinSourceMode(false)
                                setSelectedSource(key)
                                setSelectedSourceId(value.source_id)
                                setSelectedSourceCountId(value.source_count_id)
                                setIsOpenProteinAddSourceDialog(true)
                              }}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </TableCell>}
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            : <div className={pcbScss["error-block"]}>Please select the classification</div>}

        <ProteinSourceEditAddDialog
          title={isAddProteinSourceMode ? 'Add Protein Source' : `Edit Protein Source`}
          isOpen={isOpenProteinAddSourceDialog}
          handleCancelAction={() => {
            setIsOpenProteinAddSourceDialog(false)
          }}

          handleSaveAction={(newProteinSourceName, newProteinSourceId, selectedClsNameInDialog, oldSelectedClsName, oldSelectedSourceName, sourceCountId) => {
            let newJsonObject = {
              ...clsSourceObj,
            };
            if (!isAddProteinSourceMode) {
              // Edit Protein Source Mode 
              newJsonObject[selectedClsNameInDialog].source[newProteinSourceName] = clsSourceObj[oldSelectedClsName].source[oldSelectedSourceName]

              delete newJsonObject[oldSelectedClsName].source[oldSelectedSourceName];
            } else {
              newJsonObject[selectedClsNameInDialog].source[newProteinSourceName] = {
                source_status: 1,
                source_id: newProteinSourceId,
                source_count_id: sourceCountId
              }
            }

            setClsSourceObj(newJsonObject)
            setIsOpenProteinAddSourceDialog(false)
          }}
          isAddMode={isAddProteinSourceMode}
          nameValue={selectedSource}
          sourceId={selectedSourceId}
          clsObj={clsSourceObj}
          selectedClsName={selectedClsName}
          oldSelectedClsName={selectedClsName}
          oldSelectedSourceName={selectedSource}
          sourceCountId={selectedClsName !== "" ? Object.entries(clsSourceObj[selectedClsName].source).length + 1 : 0}
          selectedClsCountId={selectedClsCountId}
        />
      </div>
    </div>
  </div>)
};

export default ProteinClsBlock;