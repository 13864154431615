// -----official tools & third tools-------------------------------------------------------
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TableRow, Button, Box, Paper, Table, TableBody, TableContainer, IconButton, TableCell, TextField } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import ppsScss from "assets/scss/views/ProteinPeptideSettings.module.scss";
import listLayoutScss from "assets/scss/layout/ListLayout.module.scss";
import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import _scss from 'layout/Crud.module.scss';
import StyledTableCell from 'styledComponents/StyledTableCell';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig } from "utils/network/axios";
import cookie from "utils/cookie";
import paths from 'utils/webPath';

//---------------------------------------------------------------------------
const texts = {
  title: 'Edit the Introduction of ',
}

const Div = ({ scssName, ...props }) => (
  <div {...props} className={props.className ? props.className : _scss[scssName]}>
    {props.children}
  </div>
)

const MuiTableContainer = (props) => (
  <TableContainer component={Paper} variant="outlined" className={"table-container"}>
    <Table {...props} aria-label="a dense table">
      {props.children}
    </Table>
  </TableContainer>
)

// const MuiTableCell = (props) => (
//   <StyledTableCell {...props} style={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
//     {props.children}
//   </StyledTableCell>
// )

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell {...props} style={{ fontSize: 20, width: 190 }} align='center'
    component="th" scope="row" className="bgc-grey50">
    {props.children}
  </StyledTableCell>
)

//---------------------------------------------------------------------------
const PeptideIntroEdit = () => {
  // -----variables-------------------------------------------------------
  const classId = parseInt(cookie.getCookie(cookie.keys.classId))

  const [output, setOutput] = useState({}) //all of output
  const [rowsArray, setRowsArray] = useState({}) 
  const [updatedResultArray, setUpdatedResultArray] = useState([]);
  const [resultArray, setResultArray] = useState([]);
  const [displayname, setDisplayname] = useState("");

  const [info, setInfo] = useState(snackInfo.init())
  const [isLoading, setIsLoading] = useState(true)
  const [numResultError, setResultError] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isResultCode200, setResultCode200] = useState(false)

  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // -----functions-------------------------------------------------------
  const eventResult = () => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.readPeptideIntro,
      data: {
        "ind": classId,
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          let output = result.data
          setOutput(output)
          // console.log('output',output)

          const newList = [
            { name: 'Function', value: output.display_name || "N.D" },
            { name: 'Introduction', value: output.introduction || "N.D" },
            { name: 'Primary literature', value: output.literature || "N.D" },
            { name: 'Additional Information', value: output.additional_informations || "N.D" },
          ]
          setRowsArray(newList)
          setUpdatedResultArray(newList)
          setDisplayname(output.display_name)
          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }
    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
    })
  }
  const saveEditResult = () => {
    let response=[]
    // console.log('isEdit',isEdit)
    if(isEdit===false){
      response= rowsArray.map(item => item.value)   
      // console.log("01response", response)

    }else{
      response = resultArray.map(item => item.value)   
      // console.log("02response", response)
    }
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.editPeptideIntro,//editPeptideIntro
      data: {
        "ind": classId,
        "new_introduction": response[1],
        "new_literature": response[2],
        "new_information": response[3],
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          setInfo(snackInfo.open(snackType.success, result.data.message))
          break;
        default:

          setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
    })
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    cookie.setCookie(cookie.keys.homeSelectType, "peptide")
    navigate(paths.site.home)
    closeDialog();
  };

  const handleValueChange = (index, newValue) => {
    const updatedArray = [...updatedResultArray];
    updatedArray[index].value = removeTag(newValue)
    setResultArray(updatedArray)
    // console.log('updatedArray', updatedArray)
  };
  const removeTag = (hasTagValue) => {
    const withoutStartP = hasTagValue.replace(/^<p>/, '');
    const noTag = withoutStartP.replace(/<\/p>$/, '');

    return noTag
  };
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (isResultCode200 === false && isTimeout === false && numResultError < 5)
      eventResult();
  }, [output, updatedResultArray])

  // -----render-------------------------------------------------------
  return (
    <Div className={`${ppsScss["main"]} ${listLayoutScss["main"]}`}>
      <Div className={`${ppsScss["page-title-block"]}  ${listLayoutScss["page-title-block"]}`}>
        <IconButton onClick={() => { navigate(paths.site.proteinPeptideSettings) }} ><ArrowBackIosIcon fontSize="small" style={{ color: v.grey70 }} /></IconButton>
        {texts.title} {displayname}
      </Div>

      <MuiTableContainer size='small'>
        <TableBody >
          {isLoading ? <LoadingAnime /> :
            <TableCell>
              {rowsArray.map((item, index) => (
                <TableRow key={index} style={{ width: '100%', margin: '10px', maxWidth: '1040px' }}>
                  <MuiTableHeadCell1st>{item.name}</MuiTableHeadCell1st>
                  {/* {console.log(Object.values(item)[1])} */}
                  {item.name === 'Function' ? (<Div style={{ margin: '10px', fontSize: "20px" }}>{item.value}</Div>) : (
                    <div style={{ width: '100%', margin: '10px', maxWidth: '1040px' }}>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={editor => {
                          editor.ui.view.editable.element.style.height = '200px';
                        }}
                        data={item.value}
                        onChange={(event, editor) => {
                          setIsEdit(true)
                          const newData = editor.getData();
                          handleValueChange(index, newData);
                        }}
                        style={{ width: '100%', margin: '10px' }}
                      />
                    </div>)
                  }
                </TableRow>))
              }
            </TableCell>
          }
        </TableBody>
      </MuiTableContainer>

      {/* button */}
      <Box scssName={'button'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" color="grey" onClick={openDialog}>Cancel</Button>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: '#4d7dae' }} onClick={() => { saveEditResult(); }}>
          Save
        </Button>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Save"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Saving these changes will overwrite previous change.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button >
              <Link to={paths.site.proteinPeptideSettings}>Leave</Link>
            </Button>
            <Button onClick={() => {
              handleConfirm();
              // saveEditResult();
            }} variant="contained">Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />
    </Div>
  )
}
export default PeptideIntroEdit