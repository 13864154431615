import Cookies from "universal-cookie";

const keys = {
  token: "token",
  username: "username",
  roleId:"roleId",
  homeSelectType: "homeSelectType", // TODO: Will delete
  listSelectType: "listSelectType",
  selectedFilterProteinCls: "selectedFilterProteinCls",
  selectedFilterProteinSource: "selectedFilterProteinSource",
  selectedFilterProteinSourceId: "selectedFilterProteinSourceId",
  selectedFilterPeptideCls: "selectedFilterPeptideCls",
  selectedFilterPeptideClsId: "selectedFilterPeptideClsId",
  selectedRole: "selectedRole",
  dfbpId: "dfbpId",
  selectedSettingType: "selectedSettingType",
  classId:"classId"
};

const cookies = new Cookies();

/**
 * set cookie's key and value
 * @param {string} key
 * @param {*} value
 */
const setCookie = (key, value) => {
  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
  });
  // console.log(cookies.get(key));
};

const setCookieOneYearExpired = (key, value) => {
  // Make cookie expired after 1 year
  let today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  let oneYear = today;

  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: oneYear,
  });
  // console.log(cookies.get(key));
};

/**
 * get cookie's value
 * @param {string} key
 */
const getCookie = (key) => {
  if (cookies.get(key) === undefined) {
    return "";
  }
  return cookies.get(key);
};

/**
 * remove cookie
 * @param {string} key
 */
const removeCookie = (key) => {
  cookies.remove(key);
};

/**
 * remove all cookie in current domain
 */
const removeAllCookie = () => {
  const allCookies = cookies.getAll();

  for (let cookieName in allCookies) {
    cookies.remove(cookieName, { path: "/" });
  }
};

const cookie = {
  setCookie,
  setCookieOneYearExpired,
  getCookie,
  removeCookie,
  removeAllCookie,
  keys,
};
export default cookie;
