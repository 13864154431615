import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { FormControl, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

// import scssCommon from 'assets/scss/common.scss';
import listTableScss from 'assets/scss/components/ListTable.module.scss';
import "assets/scss/general.scss";
import 'assets/scss/index.scss';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, TableHeadRow } from 'components/MuiTable';

import v from "assets/scss/_variables.scss";
import DoubleConfirmDialog from 'components/DoubleConfirmDialog';
import { snackBarObj, snackType } from 'components/SnackBar';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbarInfo } from "store/mainSlice";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";
import { roleList } from 'views/AccountManagement';

//---------------------------------------------------------------------------
const AccountManagementTable = (props) => {

  const [userList, setUserList] = useState([])

  const [selectedUser, setSelectedUser] = useState('')
  const [roleNameToUpdate, setRoleNameToUpdate] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')
  const [roleIdToUpdate, setRoleIdToUpdate] = useState('')
  const [isOpenUpdateRoleConfirm, setIsOpenUpdateRoleConfirm] = useState(false)

  const [selectedStatusNum, setSelectedStatusNum] = useState(null)
  const [isOpenUpdateStatusConfirm, setIsOpenUpdateStatusConfirm] = useState(false)

  const dispatch = useDispatch()

  let texts = {
    menus: [
      'Show up to',
    ],
  }
  const itemsPerPageList = [10, 50, 100,]
  itemsPerPageList.map((number) => (texts.menus.push(`${number} items`)))

  function isRoleOrStatus(columnName) {
    return columnName === "#" || columnName === 'Role' || columnName === 'Enabled'
  }

  const showStatus = (status, item) => {
    return <IconButton aria-label="disable-enable-protein-class" size="small"
      disabled={item.isLoadingStatus}
      onClick={(event) => {
        setSelectedStatusNum(item.status)
        setSelectedUserId(item.user_id)
        setSelectedUser(item.name)

        setIsOpenUpdateStatusConfirm(true)
      }}>
      {status === 1 ?
        <ToggleOnIcon fontSize="medium" sx={{ color: v.success2 }} /> : <ToggleOffIcon fontSize="medium" sx={{ color: v.error }} />}
    </IconButton>
  }

  const updateUserRoleLoadingStatus = (id, newLoadingStatus) => {
    const userIndex = userList.findIndex(user => user.user_id === id);

    if (userIndex === -1) return;

    const newUsers = [...userList];

    newUsers[userIndex] = {
      ...newUsers[userIndex],
      isLoading: newLoadingStatus
    };

    setUserList(newUsers);
  };


  const updateUserStatusLoadingStatus = (id, newLoadingStatus) => {
    const userIndex = userList.findIndex(user => user.user_id === id);

    if (userIndex === -1) return;

    const newUsers = [...userList];

    newUsers[userIndex] = {
      ...newUsers[userIndex],
      isLoadingStatus: newLoadingStatus
    };

    setUserList(newUsers);
  };


  const updateRole = (newValue, user_id) => {
    updateUserRoleLoadingStatus(user_id, true)

    let inputValue = {
      "user_id": user_id,
      "new_role_id": newValue
    }

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.changeRole,
      data: inputValue
    }))
      .then(result => {
        const data = result.data;
        updateUserRoleLoadingStatus(user_id, false)
        switch (data.result_code) {
          case 200:
            props.updateRoleSuccessAction(user_id, newValue)
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
            break;
          default:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: result.data.message })));
            break;
        }

      }).catch(err => {
        updateUserRoleLoadingStatus(user_id, false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const updateStatus = (selectedStatus) => {
    updateUserStatusLoadingStatus(selectedUserId, true)
    let newStatus = 1;

    if (selectedStatus === 1) {
      newStatus = 2
    }

    let inputValue = {
      "user_id": selectedUserId,
      "new_status": newStatus
    }

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.updateUserStatus,
      data: inputValue
    }))
      .then(result => {
        const data = result.data;
        updateUserStatusLoadingStatus(selectedUserId, false)
        switch (data.result_code) {
          case 200:
            props.updateStatusSuccessAction(selectedUserId, newStatus)
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
            break;
          default:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: result.data.message })));
            break;
        }

      }).catch(err => {
        updateUserStatusLoadingStatus(selectedUserId, false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }



  const TableBodyCell = ({ item, columTitles, exceptIndexes }) => {
    return (
      columTitles.map((cell, index) =>
        exceptIndexes.includes(index) ? null : (<TableCell
          align={isRoleOrStatus(cell.name) ? "center" : "left"}
          key={index} style={{ minWidth: '150px' }}>

          {cell.apiKey === 'role_id' ?
            <FormControl size="small">
              <Select
                disabled={item.isLoadingRole}
                value={item[cell.apiKey]}
                style={{ minWidth: '120px' }}
                onChange={(event) => {
                  const newRoleId = event.target.value;
                  setRoleIdToUpdate(newRoleId)
                  setSelectedUserId(item.user_id)
                  setSelectedUser(item.name)
                  setRoleNameToUpdate(roleList.filter(role => role.value === newRoleId)[0].name)

                  setIsOpenUpdateRoleConfirm(true)
                }}
              >
                {roleList.filter(role => role.key !== 'all').map((item) => (
                  <MenuItem value={item.value} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            : cell.apiKey === 'status' ? showStatus(item[cell.apiKey], item) : item[cell.apiKey]}

        </TableCell>)
      )
    )
  }

  useEffect(() => {
    const tmpUserList = props.items.map(item => ({
      isLoadingRole: false,
      isLoadingStatus: false,
      ...item,
    }))
    setUserList(tmpUserList)
    return () => {
    };
  }, [props.items]);

  return (
    <div className={'frame-table'}>
      <TableContainer component={Paper} variant="outlined"
        style={{ maxWidth: '2500px', overflowX: 'auto' }}
      >
        {
          props.isLoading
            ? <LoadingAnime /> :
            <Table size="small">
              <TableHeadRow>
                {props.tableColumnTitles.map((cell, index) => (
                  <MuiTableCell key={index} align={isRoleOrStatus(cell.name) ? 'center' : "left"}>{cell.name}</MuiTableCell>
                ))}
              </TableHeadRow>
              <TableBody className={listTableScss["table-row"]}>

                {props.items.length === 0 ?
                  <TableRow>
                    <TableCell component="th" scope="row" align="center" colSpan={5} sx={{ pt: 3, pb: 3, fontSize: 16 }}>
                      There is no matching data
                    </TableCell>
                  </TableRow> : props.items.map((item, index) => (
                    <TableRow
                      key={item.user_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {index + props.itemNo.start}
                      </TableCell>

                      {TableBodyCell({ item: item, columTitles: props.tableColumnTitles, exceptIndexes: props.exceptIndexes })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>}

      </TableContainer>

      <DoubleConfirmDialog
        content={`Are you sure to update ${selectedUser} to "${roleNameToUpdate}" ?`}
        isOpen={isOpenUpdateRoleConfirm}
        handleClose={() => { setIsOpenUpdateRoleConfirm(false) }}
        handleConfirmAction={() => {
          setIsOpenUpdateRoleConfirm(false)
          updateRole(roleIdToUpdate, selectedUserId)
        }}
      />

      <DoubleConfirmDialog
        content={`Are you sure to ${selectedStatusNum === 1 ? 'disable' : 'enable'} ${selectedUser} ?`}
        isOpen={isOpenUpdateStatusConfirm}
        handleClose={() => { setIsOpenUpdateStatusConfirm(false) }}
        handleConfirmAction={() => {
          setIsOpenUpdateStatusConfirm(false)
          updateStatus(selectedStatusNum)
        }}
      />
    </div>
  )
}
export default AccountManagementTable