// -----official tools & third tools-------------------------------------------------------
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// -----custom tools-------------------------------------------------------
import pceadScss from "assets/scss/components/Protein/ProteinClsEditAddDialog.module.scss";
import { snackBarObj, snackType } from 'components/SnackBar';
import { setSnackbarInfo } from "store/mainSlice";
import { dialogMaxWidth, handleKeyDown } from "utils/generalVar";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";


//---------------------------------------------------------------------------
const ProteinClsEditAddDialog = (props) => {
  // -----variables-------------------------------------------------------
  const [newProteinClsId, setNewProteinClsId] = useState("");
  const [newProteinClsIdCodeC, setNewProteinClsIdCodeC] = useState("");
  const [newProteinClsName, setNewProteinClsName] = useState("");
  const [isOpenDoubleConfirmDialog, setIsOpenDoubleConfirmDialog] = useState(false);

  const [isLoadingEditCls, setIsLoadingEditCls] = useState(false);
  const [isLoadingAddCls, setIsLoadingAddCls] = useState(false);

  const dispatch = useDispatch();

  // -----functions-------------------------------------------------------
  useEffect(() => {
    if (props.isAddMode) {
      setNewProteinClsName("")
      setNewProteinClsId("")
      setNewProteinClsIdCodeC("")
    } else {
      // Edit Mode
      props.nameValue !== undefined && setNewProteinClsName(props.nameValue);
      if (props.clsId !== undefined && props.clsId !== null) {
        setNewProteinClsId(props.clsId)
      }

      if (props.clsIdCode_c !== undefined && props.clsIdCode_c !== null) {
        setNewProteinClsIdCodeC(props.clsIdCode_c)
      }

    }
    return () => {
    };
  }, [props.isOpen]);


  const cancelAction = () => {
    if (newProteinClsName !== "" || newProteinClsId !== null) {
      // setIsOpenDoubleConfirmDialog(true)
      props.handleCancelAction()
    } else {
      props.handleCancelAction()
    }
  }

  const editProteinCls = () => {
    setIsLoadingEditCls(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.editProteinClsSource,
      data: {
        "type": 1,
        "cls_id": Number(props.clsId),
        "name": newProteinClsName
      }
    }))
      .then(result => {
        setIsLoadingEditCls(false)
        switch (result.data.result_code) {
          case 200:
            dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
            props.handleSaveAction(newProteinClsName, newProteinClsId, newProteinClsIdCodeC, props.clsCountId)
            break;
          default:
        }

      }).catch(err => {
        setIsLoadingEditCls(false)
        handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
      })
  }

  const addProteinCls = () => {
    let isPass = true;
    setIsLoadingAddCls(true)

    if (newProteinClsId === "") {
      dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: "Code_P field cannot be empty." })));
      isPass = false;
    }

    if (newProteinClsIdCodeC === "") {
      dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: "Code_C field cannot be empty." })));
      isPass = false;
    }

    if (newProteinClsName === "") {
      dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.error, msg: "Name field cannot be empty." })));
      isPass = false;
    }


    let sendData = {
      "type": 1,
      "count_id": String(props.clsCountId),
      "code_p": Number(newProteinClsId),
      "code_c": Number(newProteinClsIdCodeC),
      "name": newProteinClsName
    }

    if (isPass) {
      _axios(apiPostConfig({
        endpoint: apiEndPoint.site.addProteinClsSource,
        data: sendData
      }))
        .then(result => {
          setIsLoadingAddCls(false)
          switch (result.data.result_code) {
            case 200:
              dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Create successfully" })));
              props.handleSaveAction(newProteinClsName, Number(newProteinClsId), newProteinClsIdCodeC, props.clsCountId)
              break;
            default:
          }

        }).catch(err => {
          setIsLoadingAddCls(false)
          handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
        })
    } else {
      setIsLoadingAddCls(false)
    }

  }

  const editAddAction = () => {
    if (props.isAddMode) {
      addProteinCls()
    } else {
      editProteinCls()
    }
  }

  // -----render-------------------------------------------------------
  return (<Dialog
    open={props.isOpen}
    onClose={cancelAction}
    aria-labelledby="protein-edit-add-dialog"
    aria-describedby=""
    PaperProps={dialogMaxWidth}
  >
    <DialogTitle id="protein-edit-add-dialog-title">
      {props.title}
    </DialogTitle>
    <DialogContent className={pceadScss["content"]}>
      <TextField
        label="ID"
        id="protein-count-id-size-small"
        size="small"
        type="number"
        value={props.isAddMode ? props.clsCountId : props.selectedClsCountId}
        disabled={true}
      />

      <TextField
        label="Code_P"
        id="protein-classification-id-size-small"
        size="small"
        type="number"
        value={newProteinClsId}
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        onChange={(event) => {
          setNewProteinClsId(event.target.value)
        }}
        disabled={!props.isAddMode}
      />

      <TextField
        label="Code_C"
        id="protein-classification-id-code-c-size-small"
        size="small"
        type="number"
        value={newProteinClsIdCodeC}
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        onChange={(event) => {
          setNewProteinClsIdCodeC(event.target.value)
        }}
        disabled={!props.isAddMode}
      />

      <TextField
        label="Name"
        id="protein-classification-name-size-small"
        size="small"
        value={newProteinClsName}
        onKeyDown={(event) => handleKeyDown({ event, enterAction: editAddAction })}
        onChange={(event) => {
          setNewProteinClsName(event.target.value)
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction} className={pceadScss["cancel-btn"]} >Cancel</Button>
      <Button
        disabled={isLoadingEditCls}
        onClick={editAddAction}
        className={pceadScss["confirm-btn"]}>
        {props.isAddMode ? isLoadingAddCls ? "Creating" : "Create" : isLoadingEditCls ? "Updating" : "Save"}
      </Button>
    </DialogActions>

    {/*     <DoubleConfirmDialog
      content='Are you sure to cancel this action ?'
      isOpen={isOpenDoubleConfirmDialog}
      handleClose={() => { setIsOpenDoubleConfirmDialog(false) }}
      handleConfirmAction={() => {
        setIsOpenDoubleConfirmDialog(false)
        props.handleCancelAction()
      }}
    /> */}
  </Dialog >)
};

ProteinClsEditAddDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  handleSaveAction: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  nameValue: PropTypes.string,
  clsId: PropTypes.number,
};
export default ProteinClsEditAddDialog;