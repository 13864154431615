import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControl, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


// -----custom tools-------------------------------------------------------
import _v from "assets/scss/_variables.scss";
import loginScss from "assets/scss/views/Login.module.scss";
import { snackBarObj, snackType } from 'components/SnackBar';
import cookie from "utils/cookie";
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";
import paths from "utils/webPath";


//---------------------------------------------------------------------------
const Login = () => {

  // -----variables-------------------------------------------------------
  const [email, setEmail] = useState({
    value: "",
    isErr: false,
    errMsg: ""
  });

  const [passwd, setPasswd] = useState({
    value: "",
    isErr: false,
    errMsg: ""
  });

  const [showPasswd, setShowPasswd] = useState(false)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    let passAmount = 0
    let fieldList = []
    fieldList.push(email)
    fieldList.push(passwd)

    fieldList.forEach(item => {
      if (item.value !== "" && !item.isErr) passAmount++
    })

    if (passAmount === fieldList.length) {
      setIsSubmitEnabled(true)
    } else {
      setIsSubmitEnabled(false)
    }

  }, [email, passwd]);

  const maxLenStr = (number) => {
    return `Maximum length is ${number}`
  }

  const navigate = useNavigate();

  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let redirectPath = queryParams.get('redirect');


  // -----functions-------------------------------------------------------
  const emailHandler = (value) => {
    /* eslint-disable-next-line */
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    /* eslint-disable-next-line */
    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)

    // validated or not
    if (value.search(emailRule) !== -1 && isValidEmail) {
      if (value.length > 50) {
        setEmail({ value: value, isErr: true, errMsg: maxLenStr(50) })
      } else {
        setEmail({ value: value, isErr: false, errMsg: "" })
      }

    } else {
      setEmail({ value: value, isErr: true, errMsg: "Invalid format" })
    }
  }

  const passwdHandler = (value) => {
    setPasswd({ ...passwd, value: value })
  }

  const handleTextFieldKeyPress = (event) => {
    if (event.key === 'Enter' && isSubmitEnabled) {
      event.preventDefault();
      loginEvent();
    }
  }

  const dispatch = useDispatch();

  const loginEvent = () => {
    setErrMsg("")
    setIsLoggingIn(true);

    _axios(apiPostConfig({
      endpoint: apiEndPoint.auth.login, data: {
        "email": email.value,
        "password": passwd.value
      }, isDefaultToken: true
    }))
      .then(result => {
        setIsLoggingIn(false);
        switch (result.data.result_code) {
          case 200:
            setErrMsg("")
            cookie.setCookie(cookie.keys.token, result.data["X-Auth-Token"])
            cookie.setCookie(cookie.keys.email, email.value)
            cookie.setCookie(cookie.keys.username, result.data.nickname)
            cookie.setCookie(cookie.keys.roleId, result.data.role_id)
            if (redirectPath !== "" && redirectPath !== undefined && redirectPath !== null) {
              navigate(redirectPath)
            } else {
              navigate(paths.site.home)
            }
            break;
          default:
          // setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoggingIn(false);
        setErrMsg(handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error })))
      })
  }


  return (
    <div className={loginScss["main"]} >
      <div className={loginScss["system-name"]}>SmartPP Admin</div>
      <Grid
        container
        item
        className={loginScss["content"]}
        xs={5}
      >
        <Grid className={loginScss["top-illustration"]}>Login</Grid>
        <FormGroup className={loginScss["login-filed"]}>
          <TextField
            className={loginScss["input-field"]}
            required
            id="email-required"
            placeholder="E-mail"
            fullWidth
            value={email.value}
            onChange={(event) => { emailHandler(event.target.value) }}
            helperText={email.errMsg}
            error={email.isErr}
            onKeyDown={handleTextFieldKeyPress}
          />
          <FormControl variant="outlined">
            <OutlinedInput
              className={loginScss["input-field"]}
              required
              type={showPasswd ? 'text' : 'password'}
              id="passwd-required"
              placeholder="Password"
              value={passwd.value}
              onChange={(event) => { passwdHandler(event.target.value) }}
              error={passwd.isErr}
              onKeyDown={handleTextFieldKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPasswd(!showPasswd)

                    }}
                    // onMouseDown={(event) => { handleMouseDownPassword(event) }}
                    edge="end"
                  >
                    {showPasswd ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {passwd.isErr && <FormHelperText sx={{ color: _v.error }}>{passwd.errMsg}</FormHelperText>}
          </FormControl>
        </FormGroup>

        {errMsg !== "" &&
          <Alert severity="error" className={loginScss["errBlock"]}>{errMsg}</Alert>
        }


        <Grid className={loginScss["login-btn-block"]}>
          <LoadingButton
            className={loginScss["login-btn"]}
            variant="contained"
            size="small"
            disabled={!isSubmitEnabled}
            onClick={() => { loginEvent() }}
            loading={isLoggingIn}
          >Login</LoadingButton>
        </Grid>
      </Grid>
    </div >
  )
};

export default Login;
