// -----official tools & third tools-------------------------------------------------------
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useRef, useState } from "react";

// -----custom tools-------------------------------------------------------
import SearchResultTable from "components/ListTable";
import PaginationBlock from "components/PaginationBlock";
import ProteinSearchBar from "components/Protein/ProteinSearchBar";
import { snackBarObj, snackType } from 'components/SnackBar';
import { PROTEIN } from 'components/Topbar';
import ListLayout from "layout/ListLayout";
import { useDispatch } from 'react-redux';
import { setSnackbarInfo } from 'store/mainSlice';
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import apiParams, { NumberPerPage } from "utils/network/apiParams";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import { ROLE } from 'utils/role';
import { checkOnlyCharAndNumber, invalidChar } from 'utils/generalVar';

//---------------------------------------------------------------------------
const ProteinList = () => {
	// -----variables-------------------------------------------------------
	const [tableColumnTitles, setTableColumnTitles] = useState([
		{ name: "#", apiKey: null }, { name: "SPP ID", apiKey: "SPP_ID" }, { name: "Type", apiKey: "type" }, { name: "Protein name", apiKey: "protein_name" }, { name: "Classification", apiKey: "classification" }
		, { name: "Source", apiKey: "source" }, { name: "Length", apiKey: "length" }])

	const searchHint = 'Search by SPP ID'

	const [proteinsData, setProteinsData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [itemNo, setItemNo] = useState({
		start: 1,
		end: 10,
	}) //item index 1-10

	const [pageNo, setPageNo] = useState({
		now: 1,
		last: 1,
	})
	const [itemsPerPage, setItemsPerPage] = useState(NumberPerPage) //item number per page

	const PREVIOUS = "Previous"
	const OMIT = "..."
	const NEXT = "Next"
	let words = {
		// index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
		page: [PREVIOUS, OMIT, NEXT],
	}

	const [pages, setPages] = useState([])
	const [keyword, setKeyword] = useState('')

	const [selectedFilterCls, setSelectedFilterCls] = useState("All")
	const [selectedFilterSource, setSelectedFilterSource] = useState("")

	const [selectedFilterClsId, setSelectedFilterClsId] = useState(null)
	const [selectedFilterSourceId, setSelectedFilterSourceId] = useState(null)

	const [clsList, setClsList] = useState([]);
	const [sourceList, setSourceList] = useState([]);

	const [totalAmount, setTotalAmount] = useState(null);

	const [goToValue, setGoToValue] = useState("");

	const isFirstRender = useRef(true);

	const dispatch = useDispatch()

	// -----functions-------------------------------------------------------

	const getProteinList = ({ filterCls = "", filterSource = 0 }) => {
		let sendData = {};

		if (keyword !== "") {
			sendData["keyWord"] = keyword
		}

		if (filterCls === "") {
			filterCls = selectedFilterCls
		}
		if (filterCls !== "" && filterCls !== "All") {
			const foundClsItem = clsList.find(item => item.cls_name === filterCls);
			sendData["classification"] = Number(foundClsItem.cls_id)
			setSelectedFilterClsId(Number(foundClsItem.cls_id))
		}

		if (filterSource !== 0 && filterSource !== '0' && filterSource !== "") {
			sendData["source"] = Number(filterSource);
			setSelectedFilterSourceId(Number(filterSource))
		}else{
			setSelectedFilterSourceId(null)
		}

		if(filterCls === "All"){
			setSelectedFilterClsId(null)
			setSelectedFilterSourceId(null)
		}

		setIsLoading(true)
		_axios(apiPostConfig({
			endpoint: apiEndPoint.site.proteinsSearch,
			data: apiParams.protein.search(sendData)
		}))
			.then(result => {
				setIsLoading(false)
				const data = result.data;
				switch (data.result_code) {
					case 200:
						setProteinsData(data["item(s)"])
						setTotalAmount(data["total"])

						let perPageTotalData = data["item(s)"].length
						let totalPage = Math.ceil(data.total / itemsPerPage)
						setPageNo({ now: 1, last: totalPage })
						updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })

						break;
					default:
					// handleErrMsg(data.reason, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
				}

			}).catch(err => {
				setIsLoading(false)
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const getProteinSourceListByCls = (selectedCls) => {
		const selectedClsObj = clsList.filter(item => item.cls_name === selectedCls);
		let sendData = {
			"ids": [selectedClsObj[0]["cls_id"]]
		}

		if (selectedCls !== "All") {
			_axios(apiPostConfig({
				endpoint: apiEndPoint.site.getAllProteinSourceByCls,
				data: sendData
			}))
				.then(result => {
					// setIsLoading(false);
					switch (result.data.result_code) {
						case 200:
							handleSourcesList(result.data["sources"])
							break;
						default:
						// setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
					}

				}).catch(err => {
					// setIsLoading(false);
					handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
				})

		} else {
			setSourceList([])
		}
	}

	const setFilterClsAndSource = () => {
		let sendData = {}

		const cookieSelectedCls = cookie.getCookie(cookie.keys.selectedFilterProteinCls)
		if (cookieSelectedCls === undefined || cookieSelectedCls === "" || !clsList.some(item => item.cls_name === cookieSelectedCls)) {
			setSelectedFilterCls("All")
			setSourceList([])
		} else {
			setSelectedFilterCls(cookieSelectedCls)
			getProteinSourceListByCls(cookieSelectedCls)
			sendData["filterCls"] = cookieSelectedCls
		}

		const cookieSelectedSourceId = cookie.getCookie(cookie.keys.selectedFilterProteinSourceId)
		const cookieSelectedSource = cookie.getCookie(cookie.keys.selectedFilterProteinSource)
		if (cookieSelectedSourceId === 0 || cookieSelectedSourceId === undefined || cookieSelectedSourceId === "") {
			setSelectedFilterSource("All")
		} else {
			setSelectedFilterSource(cookieSelectedSource)
			sendData["filterSource"] = cookieSelectedSourceId
		}

		getProteinList(sendData)
	}

	useEffect(() => {
		getProteinClsSourceList() // get protein classification list & source list

		const role_id = Number(cookie.getCookie(cookie.keys.roleId));
		if (role_id === ROLE.ROOT || role_id === ROLE.MANAGER) {
			setTableColumnTitles([...tableColumnTitles, { name: "Edit", apiKey: null }])
		}
		return () => {
		};
	}, []);

	useEffect(() => {
		if (clsList.length !== 0) {
			setFilterClsAndSource()
		}
		return () => {
		};
	}, [clsList]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		eventResult();
	}, [pageNo.now]);


	const setExceptIndex = (indexNames) => {
		return indexNames.map(indexName =>
			tableColumnTitles.findIndex(x => x.name === indexName)
		);
	}

	const setAlignCenterIndexes = (indexNames) => {
		return indexNames.map(indexName =>
			tableColumnTitles.findIndex(x => x.name === indexName)
		);
	}

	const eventResult = () => {
		setIsLoading(true)

		let inputValue = {
			currentPage: pageNo.now,
			lineSize: itemsPerPage,
		}

		if (keyword !== "") {
			inputValue["keyWord"] = keyword
		}
		if (selectedFilterCls !== "" && selectedFilterCls !== "All") {
			const selectedClsObj = clsList.find(item => item.cls_name === selectedFilterCls)
			inputValue["classification"] = Number(selectedClsObj.cls_id)
		}
		if (selectedFilterSource !== "" && selectedFilterSource !== "All") {
			const selectedSourceObj = sourceList.find(subObj => subObj.source_name === selectedFilterSource)
			inputValue["source"] = Number(selectedSourceObj.source_id);
		}

		_axios(apiPostConfig({
			endpoint: apiEndPoint.site.proteinsSearch,
			data: apiParams.protein.search(inputValue)

		}))
			.then(result => {
				const data = result.data;
				setIsLoading(false)
				switch (data.result_code) {
					case 200:
						setProteinsData(data["item(s)"])
						setTotalAmount(data["total"])
						let perPageTotalData = data["item(s)"].length
						let totalPage = Math.ceil(data.total / itemsPerPage)
						setPageNo({ now: pageNo.now, last: totalPage })
						updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })
						break;
					default:
					// setInfo(snackInfo.open(snackType.error, result.data.message))
				}

			}).catch(err => {
				setIsLoading(false)
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const handleClickPage = (value) => {
		if (value < 1 || value > pageNo.last) {
			setGoToValue("")
			dispatch(setSnackbarInfo({ isOpen: true, type: snackType.error, msg: "Wrong page number" }));
		} else {
			let pageNew = pageNo.now
			if (value === NEXT) { //Next
				if ((pageNew + 1) > pageNo.last)
					return;
				pageNew += 1
			}
			else if (value === PREVIOUS) //Previous
			{
				if ((pageNew - 1) < 1)
					return;
				pageNew -= 1
			}
			else if (value === OMIT) {
				//...
				return;
			}
			else {
				let num = Number(value)
				if (num !== undefined) {
					pageNew = num
				}
				else {
					return;
				}
			}
			setPageNo({ now: pageNew, last: pageNo.last })
		}
	}

	const updatePages = ({ perPageTotalData, totalData }) => {

		let itemStart = ((pageNo.now - 1) * itemsPerPage)
		if (perPageTotalData > 0 && perPageTotalData < itemsPerPage)
			setItemNo({ start: itemStart + 1, end: itemStart + perPageTotalData })
		else if (perPageTotalData === 0)
			setItemNo({ start: 0, end: 0 })
		else
			setItemNo({ start: itemStart + 1, end: itemStart + itemsPerPage })

		let totalPage = Math.ceil(totalData / itemsPerPage)

		let pages = []
		if (totalPage <= 5 && totalData !== 0) {
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous

			for (let index = 1; index <= totalPage; index++) {
				pages.push(`${index}`)
			}

			if (pageNo.now < totalPage)
				pages.push(NEXT) // Next
		} else {
			let pageStart = ((pageNo.now + 2) > totalPage ? totalPage - 2 : pageNo.now - 1) //start index on 3 button of center
			if (pageStart < 1)
				pageStart = 1
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous
			if (pageNo.now >= 3) {
				pages.push(1)
				if (pageNo.now > 3) //1 ... 3 4 5
					pages.push(OMIT) //...
			}
			let pageFirst3 = (totalPage > 3 ? 3 : totalPage)
			for (var i = pageStart; i < (pageStart + pageFirst3); i++)
				pages.push(String(i))
			if (pageNo.now <= (totalPage - 2)) {
				if (pageNo.now < (totalPage - 2)) //12 13 14 ... 16
					pages.push(OMIT) //...
				pages.push(totalPage)
			}
			if (pageNo.now < totalPage)
				pages.push(NEXT) //Next
		}
		setPages(pages)
	}

	const handleChangeSearch = (value) => {
		setKeyword(value)
	}

	const handleClickSearch = () => {

		if(checkOnlyCharAndNumber(keyword)){
			setPageNo({
				now: 1,
				last: 1,
			})
			if (pageNo.now === 1) {
				eventResult()
			}
		}else{
			dispatch(setSnackbarInfo({ isOpen: true, type: snackType.error, msg: invalidChar }));
		}
	}

	const getProteinClsSourceList = () => {
		_axios(apiGetConfig({
			endpoint: apiEndPoint.site.getAllProteinCls
		}))
			.then(result => {
				setIsLoading(false);
				switch (result.data.result_code) {
					case 200:
						handleClsList(result.data["classes"])
						break;
					default:
					// setSnackbarInfo(snackInfo.open(snackType.error, result.data.message))
				}

			}).catch(err => {
				setIsLoading(false);
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})

	}

	const handleClsList = (rowData) => {

		let clsResult = [];

		rowData.forEach(element => {
			clsResult.push({
				cls_name: element.name,
				cls_id: element.code
			})
		});

		clsResult.push(
			{
				cls_name: "All",
				cls_id: 0
			}
		)

		setClsList(clsResult)
	}

	const handleSourcesList = (rowData) => {

		let sourceResult = [];

		if (rowData.length !== 0) {
			rowData.forEach(element => {
				sourceResult.push({
					source_name: element.name,
					source_id: element.code
				})
			});

			sourceResult.push(
				{
					source_name: "All",
					source_id: 0
				}
			)
		}

		setSourceList(sourceResult)
	}


	const deleteItemForUI = (selectedId) => {
		setProteinsData(proteinsData.filter(item => item.SPP_ID !== selectedId))
	}

	// -----render-------------------------------------------------------
	return ListLayout({
		pageTitle: "Protein",
		addItemText: "Add Protein",
		totalAmount: totalAmount,
		selectedFilterClsId: selectedFilterClsId,
		selectedFilterSourceId: selectedFilterSourceId,
		keyword:keyword,
		searchBar:
			<ProteinSearchBar
				keyword={keyword}
				searchHint={searchHint}
				handleChangeSearch={handleChangeSearch}
				handleClickSearch={handleClickSearch} />,
		filterBlock: <div style={{ display: "flex", gap: "16px" }}>
			<FormControl size="small">
				<InputLabel id="cls-dropdown-label">Classification</InputLabel>
				<Select
					labelId="cls-dropdown-label"
					value={selectedFilterCls}
					label="Classification"
					style={{ minWidth: '200px' }}
					onChange={(event) => {
						setSelectedFilterCls(event.target.value)
						cookie.setCookie(cookie.keys.selectedFilterProteinCls, event.target.value)

						setSelectedFilterSource("All")
						cookie.setCookie(cookie.keys.selectedFilterProteinSource, "")

						getProteinSourceListByCls(event.target.value)
						getProteinList({ filterCls: event.target.value })
					}}
				>
					{clsList.map((item) => (
						<MenuItem value={item.cls_name} key={item.cls_id}>
							{item.cls_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{sourceList.length !== 0 && <FormControl size="small">
				<InputLabel id="source-dropdown-label">Source</InputLabel>
				<Select
					labelId="source-dropdown-label"
					value={selectedFilterSource}
					label="Source"
					style={{ minWidth: '200px' }}
					onChange={(event) => {
						setSelectedFilterSource(event.target.value)
						cookie.setCookie(cookie.keys.selectedFilterProteinSource, event.target.value)

						let selectedSourceList = sourceList.filter(item => item.source_name === event.target.value)
						let selectedSourceId = 0;
						if (selectedSourceList.length !== 0) {
							selectedSourceId = selectedSourceList[0]["source_id"]
						}
						cookie.setCookie(cookie.keys.selectedFilterProteinSourceId, selectedSourceId)

						getProteinList({ filterCls: selectedFilterCls, filterSource: selectedSourceId })
					}}
				>
					{sourceList.map((item) => (
						<MenuItem value={item.source_name} key={item.source_name}>
							{item.source_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>}
		</div>,
		tableBlock:
			<SearchResultTable
				tableColumnTitles={tableColumnTitles}
				searchHint={searchHint}
				isLoading={isLoading}
				items={proteinsData}
				itemNo={itemNo}
				type={PROTEIN}
				keyword={keyword}
				exceptIndexes={setExceptIndex(["#", "Edit"])}
				alignLCenterIndexes={setAlignCenterIndexes(["#", "Edit"])}
				deleteItemForUI={deleteItemForUI}
				noDataColSpan={8}
			/>,
		paginationBlock:
			<PaginationBlock
				pages={pages}
				pageNo={pageNo}
				words={words}
				handleClickPage={handleClickPage}
				goToValue={goToValue}
				onInputChangeInGoTo={(event) => {
					// Replace any non-digit characters with an empty string
					const newValue = event.target.value.replace(/\D/g, '');
					setGoToValue(newValue)
				}}
			/>
	})
};

export default ProteinList;