// -----official tools & third tools-------------------------------------------------------
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useRef, useState } from "react";

// -----custom tools-------------------------------------------------------
import AccountManagementTable from 'components/AccountManagement/AccountManagementTable';
import PaginationBlock from "components/PaginationBlock";
import ProteinSearchBar from "components/Protein/ProteinSearchBar";
import { snackBarObj, snackType } from 'components/SnackBar';
import { ACCOUNT_MANAGEMENT } from 'components/Topbar';
import ListLayout from "layout/ListLayout";
import { useDispatch } from 'react-redux';
import { setSnackbarInfo } from 'store/mainSlice';
import cookie from 'utils/cookie';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiPostConfig, handleErrMsg } from "utils/network/axios";

//---------------------------------------------------------------------------
export const roleList = [{
	name: "Root",
	key: "root",
	value: 2
}, {
	name: "Manager",
	key: "manager",
	value: 3
}, {
	name: "Admin",
	key: "admin",
	value: 4
}, {
	name: "User",
	key: "user",
	value: 5
}, {
	name: "All",
	key: "all",
	value: -1
}];

const AccountManagement = () => {
	// -----variables-------------------------------------------------------
	const tableColumnTitles = [
		{ name: "#", apiKey: null }, { name: "Email", apiKey: "email" }, { name: "Name", apiKey: "name" }, { name: "Role", apiKey: "role_id" }, { name: "Enabled", apiKey: "status" }]

	const searchHint = 'Search Email or Name'
	const [userList, setUserList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const isFirstRender = useRef(true);

	const [itemNo, setItemNo] = useState({
		start: 1,
		end: 10,
	}) //item index 1-10

	const [pageNo, setPageNo] = useState({
		now: 1,
		last: 1,
	})
	const [itemsPerPage, setItemsPerPage] = useState(10) //item number per page

	const PREVIOUS = "Previous"
	const OMIT = "..."
	const NEXT = "Next"
	let words = {
		// index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
		page: [PREVIOUS, OMIT, NEXT],
	}

	const [pages, setPages] = useState([])
	const [keyword, setKeyword] = useState('')

	const [selectedFilterRole, setSelectedFilterRole] = useState(-1)

	const [totalAmount, setTotalAmount] = useState(null);
	const [goToValue, setGoToValue] = useState("");

	const dispatch = useDispatch()

	// -----functions-------------------------------------------------------

	useEffect(() => {
		const selectedRoleObj = getSelectedRoleObj()
		if (selectedRoleObj.hasValue) {
			if (selectedRoleObj.value !== -1) {
				setSelectedFilterRole(selectedRoleObj.value)
			}
		}

		eventResult()
		return () => {
		};
	}, []);


	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		eventResult()
	}, [pageNo.now]);


	const setExceptIndex = (indexNames) => {
		return indexNames.map(indexName =>
			tableColumnTitles.findIndex(x => x.name === indexName)
		);
	}

	const setAlignCenterIndexes = (indexNames) => {
		return indexNames.map(indexName =>
			tableColumnTitles.findIndex(x => x.name === indexName)
		);
	}

	const eventResult = () => {
		setIsLoading(true)

		let inputValue = {
			currentPage: pageNo.now,
			lineSize: itemsPerPage,
		}

		if (keyword !== "") {
			inputValue.keyWord = keyword
		}

		const selectedRoleObj = getSelectedRoleObj()
		if (selectedRoleObj.hasValue) {
			if (selectedRoleObj.value !== -1) {
				inputValue["filterValue"] = selectedRoleObj.value
			}
		}

		_axios(apiPostConfig({
			endpoint: apiEndPoint.site.getAllUsers,
			data: inputValue
		}))
			.then(result => {
				const data = result.data;
				setIsLoading(false)
				switch (data.result_code) {
					case 200:
						const tmpUserList = data["users"];
						const totalUser = data["total"]
						tmpUserList.sort((a, b) => a.email.localeCompare(b.email));
						setUserList(tmpUserList)
						setTotalAmount(totalUser)


						let perPageTotalData = tmpUserList.length
						let totalPage = Math.ceil(data.total / itemsPerPage)
						setPageNo({ now: pageNo.now, last: totalPage })
						updatePages({ totalData: data.total, perPageTotalData: perPageTotalData })
						break;
					default:
						dispatch(setSnackbarInfo(snackBarObj({
							isOpen: true, type: snackType.error, msg: result.data.message
						})));
				}

			}).catch(err => {
				setIsLoading(false)
				handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
			})
	}

	const handleClickPage = (value) => {
		if (value < 1 || value > pageNo.last) {
			setGoToValue("")
			dispatch(setSnackbarInfo({ isOpen: true, type: snackType.error, msg: "Wrong page number" }));
		} else {
			let pageNew = pageNo.now
			if (value === NEXT) { //Next
				if ((pageNew + 1) > pageNo.last)
					return;
				pageNew += 1
			}
			else if (value === PREVIOUS) //Previous
			{
				if ((pageNew - 1) < 1)
					return;
				pageNew -= 1
			}
			else if (value === OMIT) {
				//...
				return;
			}
			else {
				let num = Number(value)
				if (num !== undefined) {
					pageNew = num
				}
				else {
					return;
				}
			}
			setPageNo({ now: pageNew, last: pageNo.last })
		}
	}

	const updatePages = ({ perPageTotalData, totalData }) => {

		let itemStart = ((pageNo.now - 1) * itemsPerPage)
		if (perPageTotalData > 0 && perPageTotalData < itemsPerPage)
			setItemNo({ start: itemStart + 1, end: itemStart + perPageTotalData })
		else if (perPageTotalData === 0)
			setItemNo({ start: 0, end: 0 })
		else
			setItemNo({ start: itemStart + 1, end: itemStart + itemsPerPage })

		let totalPage = Math.ceil(totalData / itemsPerPage)

		let pages = []
		if (totalPage <= 5 && totalData !== 0) {
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous

			for (let index = 1; index <= totalPage; index++) {
				pages.push(`${index}`)
			}

			if (pageNo.now < totalPage)
				pages.push(NEXT) // Next
		} else {
			let pageStart = ((pageNo.now + 2) > totalPage ? totalPage - 2 : pageNo.now - 1) //start index on 3 button of center
			if (pageStart < 1)
				pageStart = 1
			if (pageNo.now > 1)
				pages.push(PREVIOUS) // Previous
			if (pageNo.now >= 3) {
				pages.push(1)
				if (pageNo.now > 3) //1 ... 3 4 5
					pages.push(OMIT) //...
			}
			let pageFirst3 = (totalPage > 3 ? 3 : totalPage)
			for (var i = pageStart; i < (pageStart + pageFirst3); i++)
				pages.push(String(i))
			if (pageNo.now <= (totalPage - 2)) {
				if (pageNo.now < (totalPage - 2)) //12 13 14 ... 16
					pages.push(OMIT) //...
				pages.push(totalPage)
			}
			if (pageNo.now < totalPage)
				pages.push(NEXT) //Next
		}
		setPages(pages)
	}

	const getSelectedRoleObj = () => {

		const selectedRole = cookie.getCookie(cookie.keys.selectedRole)

		if (selectedRole === undefined || selectedRole === "" || selectedRole === "-1" || selectedRole === -1) { return { hasValue: false, value: null } } else {
			return { hasValue: true, value: Number(selectedRole) }
		}
	}

	const handleChangeSearch = (value) => {
		setKeyword(value)
	}

	const handleClickSearch = () => {

		setPageNo({
			now: 1,
			last: 1,
		})
		if (pageNo.now === 1) {
			eventResult()
		}
	}


	return ListLayout({
		pageTitle: "Account Management",
		totalAmount: totalAmount,
		type: ACCOUNT_MANAGEMENT,
		searchBar:
			<ProteinSearchBar
				keyword={keyword}
				searchHint={searchHint}
				handleChangeSearch={handleChangeSearch}
				handleClickSearch={handleClickSearch}
			/>,
		filterBlock: <div style={{ display: "flex", gap: "16px" }}>
			<FormControl size="small">
				<InputLabel id="cls-dropdown-label">Role</InputLabel>
				<Select
					labelId="cls-dropdown-label"
					value={selectedFilterRole}
					label="Role"
					style={{ minWidth: '200px' }}
					onChange={(event) => {
						setSelectedFilterRole(event.target.value)
						cookie.setCookie(cookie.keys.selectedRole, event.target.value)

						setPageNo({
							now: 1,
							last: 1,
						})
						if (pageNo.now === 1) {
							eventResult()
						}
					}}
				>
					{roleList.map((item) => (
						<MenuItem value={item.value} key={item.name}>
							{item.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

		</div>,
		tableBlock:
			<AccountManagementTable
				tableColumnTitles={tableColumnTitles}
				searchHint={searchHint}
				isLoading={isLoading}
				items={userList}
				itemNo={itemNo}
				type={ACCOUNT_MANAGEMENT}
				exceptIndexes={setExceptIndex(["#", "Actions"])}
				alignLCenterIndexes={setAlignCenterIndexes(["#", "Actions"])}
				updateRoleSuccessAction={(id, roleValue) => {
					const userIndex = userList.findIndex(user => user.user_id === id);

					if (userIndex === -1) return;

					const newUsers = [...userList];

					newUsers[userIndex] = {
						...newUsers[userIndex],
						role_id: roleValue
					};

					setUserList(newUsers);
				}}
				updateStatusSuccessAction={(id, statusValue) => {
					const userIndex = userList.findIndex(user => user.user_id === id);

					if (userIndex === -1) return;

					const newUsers = [...userList];

					newUsers[userIndex] = {
						...newUsers[userIndex],
						status: statusValue
					};

					setUserList(newUsers);
				}}
			/>,
		paginationBlock:
			<PaginationBlock
				pages={pages}
				pageNo={pageNo}
				words={words}
				handleClickPage={handleClickPage}
				goToValue={goToValue}
				onInputChangeInGoTo={(event) => {
					// Replace any non-digit characters with an empty string
					const newValue = event.target.value.replace(/\D/g, '');

					setGoToValue(newValue)
				}} />
	})
};

export default AccountManagement;