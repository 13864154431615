// -----official tools & third tools-------------------------------------------------------
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableRow, TextField, Button, Box, Table, TableContainer, IconButton, InputAdornment, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import _scss from 'layout/Crud.module.scss';

import { snackInfo, snackType, snackBarObj } from 'components/SnackBar';
import StyledTableCell from 'styledComponents/StyledTableCell';
import apiEndPoint from "utils/network/apiEndpoints";
import _axios, { apiGetConfig, apiPostConfig, handleErrMsg } from "utils/network/axios";
import paths from 'utils/webPath';
import { useDispatch } from 'react-redux';
import ToolSingleBlock from 'components/Peptide/ToolSingleBlock';
import { setSnackbarInfo } from "store/mainSlice";


const texts = {
  title: 'Add New Peptide Classification',
  step: [
    'Step 01',
    'Step 02',
    'Step 03 (Optional)'
  ],
  stepDesc: [
    'Create a new Classification',
    'Choose a certain type of column',
    'Add a new column'
  ],
  columnName: [
    { name: 'Prefix Name', value: '', inputHint: 'ACE', isError: false, errorMsg: '' },
    { name: 'Table Name', value: '', inputHint: 'ace_inhibitory_peptides', isError: false, errorMsg: '' },
    { name: 'Display Name', value: '', inputHint: 'ACE-inhibitory', isError: false, errorMsg: '' },],

  button: {
    reset: "Reset",
    search: "Search",
    checked: [
      'Check All',
      'Uncheck All',
    ],
  },
  newItem: {
    itemName: 'Column Name',
    itemType: 'Type'
  }
}
class indexData {
  static create(start, end) {
    return { start, end }
  }
}

const Div = ({ scssName, ...props }) => (
  <div {...props} className={props.className ? props.className : _scss[scssName]}>
    {props.children}
  </div>
)

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" sx={{ border: 0 }}>
    <Table {...props} aria-label="a dense table" sx={{ border: 0 }}>
      {props.children}
    </Table>
  </TableContainer>
)
const TableRow2Body = (props) => (
  <TableRow {...props}
    sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
    {props.children}
  </TableRow>
)
//---------------------------------------------------------------------------
const PeptideClassAdd = () => {
  // -----variables-------------------------------------------------------
  // const classId = parseInt(cookie.getCookie(cookie.keys.selectedFilterPeptideClsId))
  const [output, setOutput] = useState({}) //all of output

  //---------block1----------
  const [block1List, setBlock1List] = useState(texts.columnName)
  const [updatedBlock1, setUpdatedBlock1] = useState([]) // handle change
  //---------block2----------
  const optionSize = 3
  const [optionList, setOptionList] = useState([])
  const [dbColumnName, setDbColumnName] = useState([])
  const [columnIndex, setColumnIndex] = useState([]) //(start, end)
  const [sourceChecked, setSourceChecked] = useState([true, true,])
  const [sourceSelectIndex, setSourceSelectIndex] = useState([])
  const [block2List, setBlock2List] = useState([])

  //---------block3----------
  const dbColumnTypes = [
    {
      value: "INT",
      name: "INT"
    },
    {
      value: "DOUBLE(15,7)",
      name: "DOUBLE(15,7)"
    }, {
      value: "VARCHAR(100)",
      name: "VARCHAR(100)"
    }, {
      value: "VARCHAR(200)",
      name: "VARCHAR(200)"
    }, {
      value: "VARCHAR(300)",
      name: "VARCHAR(300)"
    },
    {
      value: "VARCHAR(400)",
      name: "VARCHAR(400)"
    },
    {
      value: "VARCHAR(500)",
      name: "VARCHAR(500)"
    },
    {
      value: "VARCHAR(600)",
      name: "VARCHAR(600)"
    }]
  const [block3List, setBlock3List] = useState([{}]);
  //---------get api----------
  const [info, setInfo] = useState(snackInfo.init())
  const [isLoading, setIsLoading] = useState(true)
  const [numResultError, setResultError] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isResultCode200, setResultCode200] = useState(false)

  const [open, setOpen] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch();
  // -----functions-------------------------------------------------------

  const eventResult = () => {
    setIsLoading(true)
    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.showPeptideEdit,
      data: {
        // "class_id": classId,
        "class_id": 3,
      }
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          let output = result.data.modify_page_info
          setOutput(output)

          const newArray = [];
          for (let i = 0; i < output.modify_page_info.length; i++) {
            const newObj = {
              name: output.modify_page_info[i], type: output.column_type[i]
            };
            newArray.push(newObj);
          }
          const dbArray = [];
          for (let i = 0; i < output.modify_page_info.length; i++) {
            const newObj = {
              name: output.DB_column_name[i], type: output.column_type[i]
            };
            dbArray.push(newObj);
          }
          setDbColumnName(dbArray)
          setOptionList(newArray)
          setColumnNameInit(newArray, dbArray)
          setColumnCheckbox(newArray)

          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
    })
  }

  const saveResult = () => {
    let objectData = []
    if (isBlock3HasData() === true) {
      objectData = [...block2List, ...block3List]
    } else {
      objectData = [...block2List]
    }
    const new_columns = objectData.reduce((result, item) => {
      result[item.name] = item.type;
      return result;
    }, {});

    let sendData = {
      "SPP": updatedBlock1[0],
      "table_name": updatedBlock1[1],
      "display_name": updatedBlock1[2],
      "new_columns": new_columns
    }
    // console.log('11 Save', sendData)

    _axios(apiPostConfig({
      endpoint: apiEndPoint.site.addPeptideCls,
      data: sendData
    })).then(result => {
      // console.log('QS result:', result)
      setIsLoading(false)
      switch (result.data.result_code) {
        case 200:
          setResultError(0)
          setResultCode200(true)
          dispatch(setSnackbarInfo(snackBarObj({ isOpen: true, type: snackType.success, msg: "Update successfully" })));
          setInfo(snackInfo.open(snackType.success, result.data.message))
          break;
        default:
          setInfo(snackInfo.open(snackType.error, result.data.message))
      }

    }).catch(err => {
      setIsLoading(false)
      setResultError(numResultError + 1)
      handleErrMsg(err, dispatch, snackBarObj({ isOpen: true, type: snackType.error }))
    })
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    navigate(paths.site.proteinPeptideSettings)
    closeDialog();
  };


  // -------handle value change
  // -block1-
  const handleChange = (value, index) => {
    let updatedArray = [...updatedBlock1];
    switch (index) {
      case 0: {
        if (!/^[a-zA-Z]+$/.test(updatedArray[0])) {
          texts.columnName[0].isError = true
          texts.columnName[0].errorMsg = "Sorry, only letters(a-z) are allowed."
          setBlock1List(texts.columnName)
        } else {
          texts.columnName[0].isError = false
          texts.columnName[0].errorMsg = ""
          setBlock1List(texts.columnName)
        }
        break;
      }
      case 1: {
        if (value.length < 3 || value.length > 60) {
          texts.columnName[1].isError = true
          texts.columnName[1].errorMsg = "Sorry, your table name must between 3 and 60 characters long."
          setBlock1List(texts.columnName)
        } else {
          texts.columnName[1].isError = false
          texts.columnName[1].errorMsg = ""
          setBlock1List(texts.columnName)
        }
        break;
      }
      case 2: {
        if (value.length < 3 || value.length > 60) {
          texts.columnName[2].isError = true
          texts.columnName[2].errorMsg = "Sorry, your table name must between 3 and 60 characters long."
          setBlock1List(texts.columnName)
        } else {
          texts.columnName[2].isError = false
          texts.columnName[2].errorMsg = ""
          setBlock1List(texts.columnName)
        }
        break;
      }
    }
    if (index === 0) {
      updatedArray[index] = 'SPP' + value.toUpperCase()
    } else {
      updatedArray[index] = value
    }
    setUpdatedBlock1(updatedArray)

  }
  // -block2-
  function setColumnNameInit(list,dbArray) {
    let check = Array(list.length)
    check.fill(false)
    setDefaultItem(list, 'SPP_ID', check)
    setDefaultItem(list, 'Peptide Sequence', check)
    setDefaultItem(list, 'Peptide Mass', check)
    setDefaultItem(list, 'Peptide Length', check)
    setDefaultItem(list, 'Pubdate', check)
    setDefaultItem(list, 'Term', check)
    setDefaultItem(list, 'Precursor Protein', check)
    setDefaultItem(list, 'Source', check)

    setSourceChecked(check)
    let select = getSelectIndex(check)
    setSourceSelectIndex(select)
    getSelectedInfo(select, dbArray)
  }
  function setDefaultItem(list, name, indexCheck) {
    let id = list.findIndex((list) => list.name === name)
    // console.log('name=', name, 'id=', id);
    if (id !== -1) {
      indexCheck[id] = true
    }
  }
  function setColumnCheckbox(item) {
    if (item !== undefined) {
      let index = []
      for (const i of Array(Math.ceil(item.length / optionSize)).keys())
        index.push(indexData.create(i * optionSize, (i * optionSize) + optionSize - 1))
      setColumnIndex(index)
    }
  }
  const handleCheckboxClick = (event) => {
    // console.log('value=', event.target.value, 'checked=', event.target.checked);
    let checked = event.target.checked
    sourceChecked[event.target.value] = checked
    setSourceChecked([...sourceChecked]);
    let select = getSelectIndex(sourceChecked)
    setSourceSelectIndex(select)
    getSelectedInfo(select, dbColumnName)
  }
  function getSelectIndex(list) {
    let ok = []
    list.forEach((x, index) => {
      if (x) {
        ok.push(index)
      }
    })
    return ok
  }
  function getSelectedInfo(selectList, dbList) {
    const sendData2 = selectList.map(index => dbList[index]);
    setBlock2List(sendData2)
  }

  const handleClickBtn = (value) => {
    switch (value) {
      case texts.button.checked[0]: //'Check All',
        let check = Array(optionList.length)
        check.fill(true)

        setSourceChecked(check)
        let select = getSelectIndex(check)
        setSourceSelectIndex(select)
        getSelectedInfo(select, dbColumnName)
        break
      case texts.button.checked[1]: //'Uncheck All',
        let uncheck = Array(optionList.length)
        uncheck.fill(false)

        setSourceChecked(uncheck)
        let unselect = getSelectIndex(uncheck)
        setSourceSelectIndex(unselect)
        getSelectedInfo(unselect, dbColumnName)
        break
    }
  }
  // -block3-
  const handleAddRow = () => {
    setBlock3List([...block3List, { name: '', type: dbColumnTypes[0].value }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...block3List];
    newRows.splice(index, 1);
    setBlock3List(newRows);
  };

  const handleColumnNameChange = (event, index) => {
    const newRows = [...block3List];
    newRows[index].name = event.target.value;
    setBlock3List(newRows);
  };

  const handleSelectChange = (event, index) => {
    const newRows = [...block3List];
    newRows[index].type = event.target.value;
    setBlock3List(newRows);
  };

  function isBlock3HasData() {
    if (Object.keys(block3List[0]).length !== 0) {
      return true
    } else {
      return false
    }
  }
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (isResultCode200 === false && isTimeout === false && numResultError < 5)
      eventResult();
  }, [optionList])

  // -----render-------------------------------------------------------
  return (
    <Div >
      <ToolSingleBlock stepText={texts.step[0]} title={texts.stepDesc[0]}
        mainBlockContent={
          block1List.map((item, index) => (
            <Div scssName={'block1'}>
              <Div scssName={'title'}>{item.name}</Div>
              {index === 0 ? <TextField
                color='grey'
                style={{ borderRadius: '8px' }}
                placeholder={item.inputHint}
                // value={item.value}
                onChange={(event) => { handleChange(event.target.value, index) }}
                // onKeyDown={handleKeyDown}
                fullWidth
                variant="outlined"
                inputProps={{ style: { borderColor: '#DFDFDF' } }}
                error={item.isError}
                helperText={item.errorMsg}
                InputProps={{
                  startAdornment: <InputAdornment position="start">SPP</InputAdornment>,
                }}
              />
                : <TextField
                  color='grey'
                  style={{ borderRadius: '8px' }}
                  placeholder={item.inputHint}
                  // value={item.value}
                  onChange={(event) => { handleChange(event.target.value, index) }}
                  // onKeyDown={handleKeyDown}
                  fullWidth
                  variant="outlined"
                  inputProps={{ style: { borderColor: '#DFDFDF' } }}
                  error={item.isError}
                  helperText={item.errorMsg}
                />}

            </Div>))

        } />
      <ToolSingleBlock stepText={texts.step[1]} title={texts.stepDesc[1]}
        mainBlockContent={
          <MuiTableContainer  >
            <TableRow>
              {columnIndex.map((value, count) => {
                return (
                  <TableRow2Body key={`body-${count}`} sx={{ border: 0 }} >
                    {
                      optionList.slice(value.start, value.end + 1).map((item, index) => {
                        let idx = (value.start + index)
                        return (
                          <StyledTableCell key={`cell-${count}-${index}`} sx={index === 0 ? { padding: 0, paddingLeft: 1, border: 0 } : { padding: 2, border: 0 }}>
                            <>
                              <FormControlLabel
                                key={`form-${count}-${index}`}
                                style={{ whiteSpace: 'pre-line' }}
                                control={
                                  <Checkbox
                                    sx={{
                                      '&.Mui-checked': { color: v.darkGreen }
                                    }}
                                    key={item.source}
                                    name={item.name}
                                    value={idx}
                                    checked={sourceChecked[idx]}
                                    onChange={handleCheckboxClick}
                                    inputProps={{ 'aria-label': 'controlled' }} />}
                                label={item.name}
                              />
                            </>
                          </StyledTableCell>
                        )
                      })}
                  </TableRow2Body>
                )
              })}
            </TableRow>
          </MuiTableContainer>
        }
        anotherBlockContent={
          <Div scssName={"selectItem-button"}>
            <Button
              className={_scss["button-checked"]}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                textTransform: "none",
              }}
              onClick={() => handleClickBtn(texts.button.checked[1])}
            >Uncheck All</Button>
            <Button
              className={_scss["button-checked"]}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                textTransform: "none",
              }}
              onClick={() => handleClickBtn(texts.button.checked[0])}
            >Check All</Button>
          </Div>
        }
      />
      <ToolSingleBlock stepText={texts.step[2]} title={texts.stepDesc[2]}
        mainBlockContent={
          <Div>
            {block3List.map((row, index) => (
              <Div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                <TextField
                  label="Custom Column"
                  size="small"
                  value={row.name}
                  onChange={(event) => handleColumnNameChange(event, index)}
                  style={{ width: "241px", marginRight: "10px" }}
                />
                <TextField
                  select
                  size="small"
                  label="Type"
                  value={row.type}
                  onChange={(event) => handleSelectChange(event, index)}
                  variant="outlined"
                  fullWidth
                // style={{ width: "300px" }}
                >
                  {dbColumnTypes.map(item => {
                    return <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                  })}
                </TextField>


                {block3List.length > 1 && <IconButton onClick={() => handleRemoveRow(index)}>
                  <RemoveIcon />
                </IconButton>}
                {index === block3List.length - 1 && (
                  <IconButton onClick={handleAddRow}>
                    <AddIcon />
                  </IconButton>
                )}
              </Div>
            ))}
          </Div>} />
      <Box scssName={'button'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" color="grey" onClick={openDialog}>
          Cancel
        </Button>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: '#4d7dae' }}
          onClick={saveResult}>
          Save
        </Button>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have unsaved changes. Do you want to save them?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: '#FF2D2DB2' }}
              onClick={() => {
                navigate(paths.site.proteinPeptideSettings)
              }} >Leave
            </Button>
            <Button onClick={() => {
              handleConfirm();
              saveResult();
            }}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Div>
  )
}
export default PeptideClassAdd